import { FC } from 'react'

import './InputsBlock.scss'

type InputsBlockProps = {
  name?: string
}

const InputsBlock: FC<InputsBlockProps> = ({ name, children }) => {
  return (
    <div className='inputs-block'>
      {name && <div className='inputs-block__name'>{name}</div>}
      {children}
    </div>
  )
}

export default InputsBlock
