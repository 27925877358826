export enum USER_DATA {
  TOKEN = 'token',
  USER_ID = 'userId'
}

class LocalStorageService {
  userId: string | null = null
  token: string | null = null

  constructor() {
    if (!(USER_DATA.TOKEN in localStorage)) localStorage.setItem(USER_DATA.TOKEN, '')
    if (!(USER_DATA.USER_ID in localStorage)) localStorage.setItem(USER_DATA.USER_ID, '')

    const { token, userId } = this.getAuthData()

    this.userId = userId || null
    this.token = token || null
  }

  getAuthData = () => {
    const token = localStorage.getItem(USER_DATA.TOKEN)
    const userId = localStorage.getItem(USER_DATA.USER_ID)

    return { token, userId }
  }
}

export default new LocalStorageService()
