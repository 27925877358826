import { FC, useEffect } from 'react'

import stepStore from '../../store/jobProgress'
import partsStore from '../../store/parts'
import viewerStore from '../../store/viewer'

import { JOB_STEP } from '../../store/jobProgress/types'

import './GenerateSupports.scss'
import { useRouteMatch } from 'react-router'
import { observer } from 'mobx-react-lite'

interface GenerateSupportsProps {}

const GenerateSupports: FC<GenerateSupportsProps> = () => {
  const {
    params: { jobPathKey, outputPathKey }
  } = useRouteMatch<{ jobPathKey: string; outputPathKey: string }>()
  const { setStep } = stepStore

  useEffect(() => {
    setStep(JOB_STEP.GENERATE_SUPPORTS)
  }, [setStep])

  useEffect(() => {
    if (partsStore.selectedJob && partsStore.list.length) {
      partsStore.setSelectedPart(partsStore.selectedJob.sourceFile.pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedJob, partsStore.list])

  useEffect(() => {
    const getJob = async () => {
      if (!partsStore.selectedJob && jobPathKey) {
        await partsStore.getJob(jobPathKey)
      }
    }
    getJob()
  }, [jobPathKey])

  useEffect(() => {
    const getJobOutput = async () => {
      await partsStore.getJobOutput(outputPathKey)
    }
    if (partsStore.selectedJob && !partsStore.selectedJobOutput && outputPathKey) {
      getJobOutput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputPathKey, partsStore.selectedJob, partsStore.selectedPart && partsStore.selectedJobOutput])

  useEffect(() => {
    const getFilesDataAndSetToViewer = async () => {
      let blobConfigData
      const arrUrls = partsStore.outputFiles.filter((file) => !!file).map((file) => file.url!)
      if (arrUrls[3]) {
        blobConfigData = await partsStore.getArrayBuffer(arrUrls[3])
      }

      viewerStore.setFilesUrls(arrUrls.slice(0, 3))
      blobConfigData && viewerStore.setDispConfigData(blobConfigData)
    }

    getFilesDataAndSetToViewer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.outputFiles])

  return <div className='generate-support'>Generate Supports</div>
}
export default observer(GenerateSupports)
