import { ROUTES } from '../../router/types'

export type TJobStep = {
  name: string
  isCompleted: boolean
  step: JOB_STEP
  path: ROUTES
  relatedToStep?: JOB_STEP
  orientType?: ORIENTATION_TYPE
}
export type TJobProgressStep = {
  [key in JOB_STEP]: TJobStep
}

export enum ORIENTATION_TYPE {
  MANUAL = 'manual',
  OPTIMIZER = 'optimizer'
}

export enum JOB_STEP {
  TAG_SURFACE = 1,
  ORIENTATION_TYPE = 2,
  MANUAL_ORIENTATION = 3,
  OPTIMIZER_OPTIONS = 4,
  DESIGN_SUPPORT = 5,
  GENERATE_SUPPORTS = 6,
  MODIFY_SUPPORTS = 7
}

export const jobProgressSteps: TJobProgressStep = {
  [JOB_STEP.TAG_SURFACE]: {
    name: 'Tag Surface',
    isCompleted: false,
    step: JOB_STEP.TAG_SURFACE,
    path: ROUTES.TAG_SURFACE
  },
  [JOB_STEP.ORIENTATION_TYPE]: {
    name: 'Orient Part',
    isCompleted: false,
    step: JOB_STEP.ORIENTATION_TYPE,
    path: ROUTES.ORIENTATION_TYPE
  },
  [JOB_STEP.MANUAL_ORIENTATION]: {
    name: 'Manual Orientation',
    isCompleted: false,
    step: JOB_STEP.MANUAL_ORIENTATION,
    path: ROUTES.MANUAL_ORIENTATION,
    relatedToStep: JOB_STEP.ORIENTATION_TYPE,
    orientType: ORIENTATION_TYPE.MANUAL
  },
  [JOB_STEP.OPTIMIZER_OPTIONS]: {
    name: 'Optimizer Options',
    isCompleted: false,
    step: JOB_STEP.OPTIMIZER_OPTIONS,
    path: ROUTES.OPTIMIZER_OPTIONS,
    relatedToStep: JOB_STEP.ORIENTATION_TYPE,
    orientType: ORIENTATION_TYPE.OPTIMIZER
  },
  [JOB_STEP.DESIGN_SUPPORT]: {
    name: 'Select Support Geometry',
    step: JOB_STEP.DESIGN_SUPPORT,
    isCompleted: false,
    path: ROUTES.DESIGN_SUPPORT
  },
  [JOB_STEP.GENERATE_SUPPORTS]: {
    name: 'Generate Supports',
    isCompleted: false,
    step: JOB_STEP.GENERATE_SUPPORTS,
    path: ROUTES.GENERATE_SUPPORTS
  },
  [JOB_STEP.MODIFY_SUPPORTS]: {
    name: 'Modify Supports',
    step: JOB_STEP.MODIFY_SUPPORTS,
    isCompleted: false,
    path: ROUTES.MODIFY_SUPPORTS
  }
}
