import React, { FC, useEffect } from 'react'
import useOnScreen from '../../../hooks/use-on-screen'

import './LoadableImage.scss'

export interface ILoadableImage {
  url: string
  alt?: string
  containerWidth?: number
  onLoad?(): void
}
const LoadableImage: FC<ILoadableImage> = ({ alt = '', url, containerWidth, onLoad = () => {} }) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const imageRef = React.useRef<HTMLImageElement | null>(null)
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const isVisible = useOnScreen(containerRef)

  useEffect(() => {
    if (!isVisible || isLoaded) return

    const onLoadImage = () => {
      setIsLoaded(true)
      onLoad()
    }

    if (imageRef.current) imageRef.current.onload = onLoadImage

    return () => {
      if (imageRef.current) imageRef.current = null
    }
  }, [isVisible, onLoad, isLoaded])

  return (
    <div
      ref={containerRef}
      className={`loadable-container light ${isLoaded && 'loaded'}`}
      style={{ height: containerWidth, width: containerWidth }}
    >
      {(isVisible || isLoaded) && (
        <img ref={imageRef} className={`loadable-container__image ${isLoaded && 'loaded'}`} src={url} alt={alt} />
      )}
    </div>
  )
}

export default LoadableImage
