import { FC } from 'react'
import { Route } from 'react-router'

import { IRouteConfig } from '../../router/types'

type RouteWithSubRoutesProps = IRouteConfig

const RouteWithSubRoutes: FC<RouteWithSubRoutesProps> = ({ ...route }) => {
  return <Route path={route.path} render={(props) => <route.component routes={route.routes} {...props} />} />
}

export default RouteWithSubRoutes
