import { FC } from 'react'
// import ReactPaginate from 'react-paginate';

import './PaginatedList.scss'

type PaginatedListProps = {}

const PaginatedList: FC<PaginatedListProps> = ({ children }) => {
  return (
    <>
      {children}
      {/* <ReactPaginate
        pageCount={2}
        pageRangeDisplayed={5}
        marginPagesDisplayed={5}
      /> */}
    </>
  )
}

export default PaginatedList
