import axios from 'axios'
// import { token } from './mock'

// TODO LATER AXIOS INTERCEPTOR WITH QUEUE

// Setup axios

const setupAxiosInterceptors = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

  // Middleware to valid requests. Add token to request
  // const onRequestSuccess = (config: any) => {
  //   config.headers.Authorization = `Bearer ${token}`
  //   return config
  // }
  // axios.interceptors.request.use(onRequestSuccess, void 0)
}

export default setupAxiosInterceptors
