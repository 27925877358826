import { ChangeEvent, FC } from 'react'

import './CheckboxInput.scss'

type CheckboxInputProps = {
  onChange: (value: any) => void
  label: string
  value: boolean
  className?: string
}

const CheckboxInput: FC<CheckboxInputProps> = ({ onChange, label, value, className }) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    onChange(value)
  }
  return (
    <div className={`general-checkbox-input general-input-style ${className}`}>
      <label className='general-checkbox-input__label'>
        <input onChange={onInputChange} type='checkbox' checked={value} className='general-checkbox-input__input' />
        <div className='general-checkbox-input__checkmark'></div>
        {label}
      </label>
    </div>
  )
}

export default CheckboxInput
