import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CircularProgressbar } from 'react-circular-progressbar'

import ProgressStepMark from './ProgressStepMark'

import overallStepsState from '../../store/overallProgress'

import 'react-circular-progressbar/dist/styles.css'
import './OverallJobProgress.scss'

type OverallJobProgressProps = {}

const OverallJobProgress: FC<OverallJobProgressProps> = () => {
  const { overallProgress, list, currentStep } = overallStepsState

  return (
    <div className='overall-job-progress'>
      <div className='overall-job-progress__top-menu'>
        <div className='overall-job-progress__top-menu__chart'>
          <CircularProgressbar strokeWidth={13} value={overallProgress} text={`${overallProgress}%`} />
        </div>
        <div className='overall-job-progress__top-menu__title'>OVERALL PROGRESS</div>
      </div>
      <div className='overall-job-progress__bottom-menu progress-list'>
        {list.map((step, index) => {
          const isNextStep = currentStep + 1 === index
          return (
            <div
              key={`overall-step-${index}`}
              className={`progress-list-item
              ${step.isDisabled && 'disabled'}
              ${step.isCompleted && 'completed'}
              ${step.isActive && 'active'}
              ${isNextStep && 'next-step'}`}
            >
              <ProgressStepMark
                isCompleted={step.isCompleted}
                isLast={index === list.length - 1}
                isActive={step.isActive}
                text={step.markNum}
                isNextStep={isNextStep}
              />
              <div className='progress-list-item__data'>
                <div className='progress-list-item__data__title'>{step.title}</div>
                {step.description && <div className='progress-list-item__data__description'>{step.description}</div>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(OverallJobProgress)
