export enum UNIT_TYPE {
  MILLIMETER = 'mm'
}

export enum PRINTER_TYPE {
  EOSM100 = 'EOSM100',
  EOSM290 = 'EOSM290',
  EOSM300 = 'EOSM300',
  EOSM400 = 'EOSM400',
  SLM280 = 'SLM280',
  RENISHAW_AM250 = 'RenishawAM250',
  RENISHAW_AM500 = 'RenishawAM500',
  ARCAM_Q20 = 'ArcamQ20'
}

export enum MATERIAL_NAME {
  TI64 = 'Ti64',
  STAILESS_STEEL_316L = '316L Stainless Steel',
  ALSI10MG = 'AlSi10Mg',
  FERRUM_C64 = 'Ferrium® C64 Steel',
  STAILESS_STEEL_14_4PH = '17-4PH Stainless Steel',
  INCONEL_1718 = 'Inconel718',
  IN_625 = 'IN-625',
  MARAGING_M300 = 'MaragingSteelM300',
  KENNAMETAL_STELLITE = 'Kennametal Stellite',
  KENNAMETAL_NISTELLE = 'Kennametal Nistelle',
  KENNAMETAL_DELCROME = 'Kennametal Delcrome',
  COBALT_CHROMIUM = 'Cobalt Chromium',
  HASTELLOY_X = 'Hastelloy X',
  INCONE_1939 = 'Inconel939'
}

export enum DESIGN_SUPPORT {
  BLOCK = 'block',
  LINE = 'line',
  PERFORATED_VOLUME = 'perforated volume'
}

export enum STRENG_DESIGN_SUPPORTS {
  CONE = 'cone',
  SOLID_VOLME = 'solid volume',
  TREE = 'tree'
}
export enum PRIMARY_ATTACHMENT_GEOMETRY {
  TOOTH = 'tooth'
}
export enum STRENG_ATTACHMENT_GEOMETRY {
  TREE = 'tree',
  SAME_AS_SUPPORT = 'same as support'
}

export const TEmptySuppField = 'empty'

export type TModelFace = {}

const STATIC_PREFIX = '/static/models/stl/binary/'
export const PRINTER_CONFIG_LIBRARY = {
  [PRINTER_TYPE.EOSM100]: {
    base: STATIC_PREFIX + 'build_plate_EOSM100.stl',
    position: [-100, -200, 200],
    zRotation: -15
  },
  [PRINTER_TYPE.EOSM290]: {
    base: STATIC_PREFIX + 'build_plate_EOSM290.stl',
    position: [-150, -350, 300],
    zRotation: -25
  },
  [PRINTER_TYPE.EOSM400]: {
    base: STATIC_PREFIX + 'build_plate_EOSM400.stl',
    position: [-300, -500, 400],
    zRotation: -40
  },
  [PRINTER_TYPE.SLM280]: {
    base: STATIC_PREFIX + 'build_plate_SLM280.stl',
    position: [-250, -350, 300],
    zRotation: -30
  },
  [PRINTER_TYPE.RENISHAW_AM250]: {
    base: STATIC_PREFIX + 'build_plate_RenishawAM250.stl',
    position: [-250, -350, 300],
    zRotation: -30
  },
  [PRINTER_TYPE.RENISHAW_AM500]: {
    base: STATIC_PREFIX + 'build_plate_RenishawAM500.stl',
    position: [-250, -350, 350],
    zRotation: -30
  },
  [PRINTER_TYPE.ARCAM_Q20]: {
    base: STATIC_PREFIX + 'build_plate_arcamQ20.stl',
    position: [-350, -350, 200],
    zRotation: -15
  }
}
