import axios, { AxiosResponse } from 'axios'
import { makeAutoObservable } from 'mobx'

import { TSourceFile } from '../../types/files'
import configState from '../jobConfig'

import localStorageService from '../../services/localStorageService'

import {
  partLoaders,
  TGenerateSuppPayload,
  TAllowedTypeToSelectedPart,
  TGenerateSupport,
  TGetDownloadUrlPayload,
  TGetDownloadUrlResponse,
  TGraphqlPartsResponse,
  TPartLoaders,
  TResponseUploadFile,
  TUploadFilePayload,
  TSubmitJobPayload,
  TUpdateJobPayload,
  TCreateJobPayload,
  TCreateJobResponse,
  TJobResponse,
  TJob,
  TJobOutput,
  TOutputFilesToViewer,
  TOutputFile,
  TJobOutputResponse
} from './types'
import { TJobConfig } from '../jobConfig/types'

const appSyncUrl = process.env.REACT_APP_APPSYNC_URL as string

class Parts {
  list: TSourceFile[] = []
  loaders: TPartLoaders = partLoaders
  selectedPart: TAllowedTypeToSelectedPart = null
  selectedJob: TJob | null = null
  apiResponse: string = ''
  jobPathKey: string | null = null
  selectedJobOutput: TJobOutput | null = null
  outputFiles: TOutputFilesToViewer[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedPart = (pathKey: string) => {
    const selectedPart = this.list.find((part) => part.pathKey === pathKey)
    if (selectedPart) {
      this.selectedPart = selectedPart
      configState.setConfigField('filename', selectedPart.name)
    }
  }

  setJobPathKey = (pathKey: string) => {
    this.jobPathKey = pathKey
  }

  getParts = async () => {
    try {
      this.loaders.fetch.inProgress = true
      let graphqlPartsResponse: AxiosResponse<TGraphqlPartsResponse> = await axios.post(
        appSyncUrl,
        {
          query: `query {
              user: sourceFileByUser(
                sortDirection: DESC
                userId: "${localStorageService.userId}"
                filter: {
                  isDeleted: { eq: false }
                }
              ) {
                sourceFiles: items {
                  id
                  name
                  pathKey
                }
              }
            }`
        },
        {
          headers: {
            Authorization: `${localStorageService.token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      this.list = graphqlPartsResponse.data.data.user.sourceFiles
      this.loaders.fetch.inProgress = false
      this.loaders.fetch.isLoaded = true
    } catch (error) {
      console.log('getParts _ error => ', error)
      this.apiResponse = 'Something went wrong!'
    }
  }

  getDownloadURL = async ({ filename, sourceFilePathKey, jobPathKey, jobOutputPathKey }: TGetDownloadUrlPayload) => {
    const response: AxiosResponse<TGetDownloadUrlResponse> = await axios.post(
      `/files/download`,
      {
        filename: filename,
        sourceFilePathKey: sourceFilePathKey,
        jobPathKey: jobPathKey,
        jobOutputPathKey: jobOutputPathKey
      },
      {
        headers: {
          Authorization: `Bearer ${localStorageService.token}`
        }
      }
    )

    return response.data.url
  }

  uploadFile = async (payload: TUploadFilePayload) => {
    const data: any = {
      filename: payload.file.name
    }
    if (payload.hasOwnProperty('sourceFilePathKey') && payload.hasOwnProperty('jobPathKey')) {
      payload.sourceFilePathKey = data.sourceFilePathKey
      payload.jobPathKey = data.jobPathKey
    }
    try {
      this.loaders.upload.inProgress = true
      const responseUploadFile: AxiosResponse<TResponseUploadFile> = await axios.post('/files/upload', data, {
        headers: {
          Authorization: `Bearer ${localStorageService.token}`
        }
      })
      await axios.put(responseUploadFile.data.url, payload.file, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })
      this.loaders.upload.inProgress = false
      // TODO update files list
    } catch (error) {
      this.apiResponse = 'Something went wrong!'
    }
  }

  deleteFile = async (sourceFilePathKey: string) => {
    try {
      this.loaders.delete.inProgress = true
      this.loaders.delete.pathKeys.push(sourceFilePathKey)
      await axios.post(
        `/files/delete`,
        { sourceFilePathKey },
        {
          headers: {
            Authorization: `Bearer ${localStorageService.token}`
          }
        }
      )
      this.list = this.list.filter((file) => file.pathKey !== sourceFilePathKey)
      this.loaders.delete.pathKeys.filter((key) => key !== sourceFilePathKey)
      if (!this.loaders.delete.pathKeys.length) {
        this.loaders.delete.inProgress = false
      }
    } catch (error) {
      console.error(error)
    }
  }

  // Actions for run/update job

  createJob = async (payload: TCreateJobPayload) => {
    try {
      const responseCreateJob: AxiosResponse<TCreateJobResponse> = await axios.post(
        `/jobs/create`,
        {
          ...payload,
          config: {
            ...payload.config,
            AdditionalConfig: payload.config.RestrictedFaces.length > 0 || payload.config.AllowedFaces.length > 0
          },
          targetVersion: '1.2.0'
        },
        {
          headers: {
            Authorization: `Bearer ${localStorageService.token}`
          }
        }
      )
      return responseCreateJob.data.jobPathKey
    } catch (error) {
      console.error('createJob _ error => ', error)
    }
  }

  updateJob = async (payload: TUpdateJobPayload) => {
    try {
      await axios.post(
        `/jobs/update`,
        {
          ...payload,
          config: {
            ...payload.config,
            AdditionalConfig: payload.config.RestrictedFaces.length > 0 || payload.config.AllowedFaces.length > 0
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorageService.token}`
          }
        }
      )
    } catch (error) {
      console.log('updateJob _ error => ', error)
    }
  }

  submitJob = async (payload: TSubmitJobPayload) => {
    try {
      await axios.post(`/jobs/submit`, payload, {
        headers: {
          Authorization: `Bearer ${localStorageService.token}`,
          'Content-Type': 'application/json'
        }
      })
    } catch (error) {
      console.log('submitJob _ error => ', error)
    }
  }

  getJob = async (pathKey: string) => {
    try {
      const responseGetJob: AxiosResponse<TJobResponse> = await axios.post(
        appSyncUrl,
        {
          query: `query {
              Job: jobByPathKey(
                pathKey: "${pathKey}"
                sortDirection: DESC
              ) {
                items {
                  id
                  pathKey
                  config
                  createdAt
                  updatedAt
                  createdAt
                  sourceFile {
                    id
                    name
                    pathKey
                  }
                  jobOutputs {
                    items {
                      pathKey
                      id
                      analysisId
                      objectiveId
                      pathKey
                      progress
                      status
                      job {
                        sourceFile {
                          pathKey
                        }
                      }
                      outputFiles {
                        items {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }`
        },
        {
          headers: {
            Authorization: `${localStorageService.token}`,
            'Content-Type': 'application/json'
          }
        }
      )

      const job = responseGetJob.data.data.Job.items[0]
      const config: TJobConfig = JSON.parse(job.config)
      configState.setJobConfig(config)

      // const jobOutput = job.jobOutputs.items.find(output => output.analysisId === )

      this.selectedJob = job
      return job
    } catch (error) {
      console.error('responseGetJob _ error => ', error)
      return null
    }
  }

  getJobOutput = async (pathKey: string) => {
    try {
      const jobOutputResponse: AxiosResponse<TJobOutputResponse> = await axios.post(
        appSyncUrl,
        {
          query: `query{
            jobOutput: jobOutputByPathKey(pathKey: "${pathKey}"){
              items {
                progress
                pathKey
                objectiveId
                job {
                  sourceFile {
                    pathKey
                  }
                }
                analysisId
                outputFiles {
                  items {
                    id
                    name
                  }
                }
              }
            }
          }`
        },
        {
          headers: {
            Authorization: `${localStorageService.token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const jobOutput = jobOutputResponse.data.data.jobOutput.items[0]
      this.selectedJobOutput = jobOutput
      this.setOutputFiles(jobOutput.outputFiles.items)
      return jobOutput
    } catch (err) {
      return null
    }
  }

  setSelectedJobOutput = (output: TJobOutput) => {
    this.selectedJobOutput = output
  }

  setOutputFiles = async (files: TOutputFile[]) => {
    const arrFiles: TOutputFilesToViewer[] = []

    files.forEach((file) => {
      if (file.name.match(/_output_strengthen_0m\.stl$/)) {
        arrFiles[2] = file
      }
      if (file.name.match(/disp_config$/)) {
        arrFiles[3] = file
      }
      if (file.name.match(/_output_0m\.stl$/) && !file.name.startsWith('1_polymerSupp')) {
        arrFiles[1] = file
      }
      if (file.name.match(/_output\.stl$/)) {
        arrFiles[0] = file
      }
    })

    let arrPromise = arrFiles.map((file) => {
      return this.getDownloadURL({
        sourceFilePathKey: this.selectedPart!.pathKey,
        jobPathKey: this.selectedJob!.pathKey,
        jobOutputPathKey: this.selectedJobOutput!.pathKey,
        filename: file.name
      })
    })

    console.log('data => ', arrFiles)

    const dataToViewer = await Promise.all(arrPromise)
    this.outputFiles = arrFiles.map((file, index) => ({ ...file, url: dataToViewer[index] }))
  }

  getArrayBuffer = async (url: string) => {
    const res = await axios.get(url, {
      responseType: 'arraybuffer'
    })
    return res.data
  }

  generateSupport = async (payload: TGenerateSuppPayload) => {
    const { sourceFilePathKey, config } = payload
    payload.config.AdditionalConfig =
      payload.config.RestrictedFaces.length > 0 || payload.config.AllowedFaces.length > 0
    const response: AxiosResponse<TGenerateSupport> = await axios.post(
      `/supports/preview`,
      {
        sourceFilePathKey,
        targetVersion: '1.2.0',
        config: config
      },
      {
        headers: {
          Authorization: `Bearer ${localStorageService.token}`
        }
      }
    )
    let url = response.data.url
    url.replace(/(amazonaws\.com\/.+\/)/, `amazonaws.com/${localStorageService.userId}/`)
    return url
  }
}

export default new Parts()
