import { FC, useEffect, useState } from 'react'

import stepStore from '../../store/jobProgress'
import configStore from '../../store/jobConfig'
import partsStore from '../../store/parts'
import viewerStore from '../../store/viewer'
import preloaderStore from '../../store/preloader'

import './ManualOrientation.scss'
import InputRange from '../../components/UI/Inputs/Range'
import { JOB_STEP, ORIENTATION_TYPE } from '../../store/jobProgress/types'
import ResetBtn from '../../components/UI/Buttons/ResetButton'
import InputsBlock from '../../components/UI/InputsBlock'
import SelectInput from '../../components/UI/Inputs/Select'
import NumberInput from '../../components/UI/Inputs/Number'
import { TJobConfig } from '../../store/jobConfig/types'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router'

const [x, y, z] = configStore.config.RotationalAngle
const initRotation = { x, y, z }
const initScale = configStore.config.scaling

const ManualOrientation: FC = () => {
  const {
    params: { pathKey }
  } = useRouteMatch<{ pathKey: string }>()
  const [rotation, setRotation] = useState(initRotation)
  const [scale, setScale] = useState(initScale[0])
  const { setStep, setOrientationType } = stepStore
  const { setConfigField, config, materialConfigs, printerConfigs } = configStore

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  const onRangeChange = (value: number, id: string) => {
    const param = id.replace(/range_/, '')
    if (param === 'scaling') setScale(value)
    else setRotation((prev) => ({ ...prev, [param]: value }))
  }
  useEffect(() => {
    const storeRotation: [number, number, number] = [rotation.x, rotation.y, rotation.z]
    configStore.setConfigField('RotationalAngle', storeRotation)
  }, [rotation])

  useEffect(() => {
    configStore.setConfigField('scaling', [scale])
  }, [scale])

  useEffect(() => {
    setStep(JOB_STEP.MANUAL_ORIENTATION)
    setOrientationType(ORIENTATION_TYPE.MANUAL)
  }, [setStep, setOrientationType])

  const reset = () => {
    setRotation(initRotation)
    setScale(1)
  }

  const onChangeConfigInput = <K extends keyof TJobConfig>(field: K) => {
    return (value: TJobConfig[K]) => {
      setConfigField(field, value)
    }
  }

  const scaleProps = {
    min: 1,
    max: 10,
    step: 1,
    initValue: scale,
    id: 'scaling',
    label: 'Scaling factor of the part (mm)',
    onRangeChange
  }
  const axisProps = (axis: keyof typeof initRotation) => ({
    min: 0,
    max: 360,
    step: 1,
    initValue: rotation[axis],
    id: `range_${axis}`,
    label: `Rotation ${axis.toUpperCase()}`,
    onRangeChange
  })
  const xProps = axisProps('x')
  const yProps = axisProps('y')
  const zProps = axisProps('z')

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  useEffect(() => {
    const selectedPart = partsStore.selectedPart
    const getDownloadUrls = async () => {
      if (selectedPart && !viewerStore.fileUrls.length) {
        preloaderStore.showPreloader()
        const urls = await partsStore.getDownloadURL({
          filename: selectedPart.name,
          sourceFilePathKey: selectedPart.pathKey
        })
        viewerStore.setFilesUrls([urls])
        preloaderStore.hidePreloader()
      }
    }
    getDownloadUrls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedPart])

  return (
    <div className='tag-surface-page general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Manual Orientation</div>
        <div className='general-page-style__content__left-block tag-surface-page__content'>
          <div className='manual-orientation'>
            <InputRange {...scaleProps} />
            <InputRange {...xProps} />
            <InputRange {...yProps} />
            <InputRange {...zProps} />
            <InputsBlock name='Job Detail'>
              <div className='row'>
                <SelectInput
                  label={'Material'}
                  value={config.material_name}
                  options={materialConfigs.map((material) => ({
                    optionLabel: material.name,
                    value: material.name
                  }))}
                  onChange={onChangeConfigInput('material_name')}
                />
                <SelectInput
                  label={'Printer'}
                  value={config.printer}
                  options={printerConfigs.map((printer) => ({
                    optionLabel: printer.name,
                    value: printer.name
                  }))}
                  onChange={onChangeConfigInput('printer')}
                />
              </div>
              <div className='row'>
                <NumberInput
                  label={'No. of lazers'}
                  value={config.NLaser[0]}
                  onChange={onChangeConfigInput('NLaser')}
                />
                <NumberInput
                  label={'Element size (mm)'}
                  value={config.elementsize[0]}
                  onChange={onChangeConfigInput('elementsize')}
                />
              </div>
              <NumberInput
                label={'Layer thickness (um)'}
                value={config.layerThickness[0]}
                onChange={onChangeConfigInput('layerThickness')}
              />
              <NumberInput
                label={'Critical self supporting angle (deg)'}
                value={config.critical_angle[0]}
                onChange={onChangeConfigInput('critical_angle')}
              />
            </InputsBlock>

            <InputsBlock name='Build Plate Parameters'>
              <NumberInput
                label={'Biuld plate temperature (C)'}
                value={config.Tplate[0]}
                onChange={onChangeConfigInput('Tplate')}
              />
              <NumberInput
                label={'Biuld plate thickness (mm)'}
                value={config.H_buildPlate[0]}
                onChange={onChangeConfigInput('H_buildPlate')}
              />
            </InputsBlock>
            <div className='orient-part__reset'>
              <ResetBtn onClick={reset} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(ManualOrientation)
