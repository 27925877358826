import { FC } from 'react'
import viewerState from '../../store/viewer'

import './ViewerPreloader.scss'

const ViewerPreloader: FC = () => {
  const isLoading = viewerState.isLoading

  return isLoading ? (
    <div className={`viewer-preloader`}>
      <div className='viewer-preloader__spinner'>File processing</div>
    </div>
  ) : null
}

export default ViewerPreloader
