import { FC } from 'react'
import { generatePath, RouteComponentProps, useRouteMatch } from 'react-router'
import { observer } from 'mobx-react-lite'

import StepButton from '../UI/Buttons/StepButton'

import jobProgressState from '../../store/jobProgress'
import partsStore from '../../store/parts'
import configState from '../../store/jobConfig'
import preloaderState from '../../store/preloader'

import './ChangeStepButton.scss'
import { ROUTES } from '../../router/types'
import { JOB_STEP } from '../../store/jobProgress/types'
import { JOB_OUTPUT_STATUS } from '../../store/parts/types'

type ChangeStepButtonProps = {
  history: RouteComponentProps['history']
}

const ChangeStepButton: FC<ChangeStepButtonProps> = ({ history }) => {
  const { nextStep, currentStep } = jobProgressState
  const { params } = useRouteMatch<{ pathKey: string; jobPathKey: string; outputPathKey: string }>()

  const moveToNextStep = ({ pathKey, jobPathKey, outputPathKey }: { pathKey?: string, jobPathKey?: string, outputPathKey?: string }) => {
    let path = ''

    if (nextStep) {

      switch (nextStep.step) {
        case JOB_STEP.ORIENTATION_TYPE: {
          path = generatePath(nextStep?.path, { pathKey })
          break
        }
        case JOB_STEP.MANUAL_ORIENTATION: {
          path = generatePath(nextStep?.path, { pathKey })
          break
        }
        case JOB_STEP.OPTIMIZER_OPTIONS: {
          path = generatePath(nextStep?.path, { pathKey })
          break
        }
        case JOB_STEP.DESIGN_SUPPORT: {
          path = generatePath(nextStep?.path, { jobPathKey, outputPathKey })
          break
        }
        case JOB_STEP.GENERATE_SUPPORTS: {
          path = generatePath(nextStep?.path, { jobPathKey, outputPathKey })
          break
        }
        case JOB_STEP.MODIFY_SUPPORTS: {
          path = generatePath(nextStep?.path, { jobPathKey, outputPathKey })
          break
        }
      }
    }

    history.push(path || ROUTES.MAIN)
  }

  const stepAction = async () => {
    switch (currentStep?.step) {
      case JOB_STEP.MANUAL_ORIENTATION: {
        try {
          preloaderState.showPreloader()
          const createdJobPathKey = await partsStore.createJob({
            config: configState.config,
            sourceFilePathKey: params.pathKey
          })
          if (createdJobPathKey) {
            await partsStore.submitJob({
              jobPathKey: createdJobPathKey,
              analysisId: 1,
              objectiveId: 0
            })

            const checkIsJobFinished = setInterval(async () => {
              const responseJob = await partsStore.getJob(createdJobPathKey)
              // TODO: what job i should to use? Job has a lot of outputs
              const jobOutput = responseJob?.jobOutputs.items.find(
                (output) => output.analysisId === 1 && output.objectiveId === 0
              )
              if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.COMPLETE) {
                moveToNextStep({ jobPathKey: createdJobPathKey, outputPathKey: jobOutput.pathKey })
                clearInterval(checkIsJobFinished)
                preloaderState.hidePreloader()
              }
              if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.ERROR) {
                console.error('Unexpected error !')
                clearInterval(checkIsJobFinished)
                preloaderState.hidePreloader()
              }
            }, 4000)
          }
        } catch (error) {
          console.log('err', error)
        }
        break
      }
      case JOB_STEP.DESIGN_SUPPORT: {
        preloaderState.showPreloader()
        const newJobPathKey = await partsStore.createJob({
          sourceFilePathKey: partsStore.selectedJobOutput!.job.sourceFile.pathKey,
          config: {
            ...configState.config,
            isStrengthening: 1
          }
        })
        await partsStore.submitJob({ jobPathKey: newJobPathKey!, analysisId: 1, objectiveId: 0 })
        const checkIsJobFinished = setInterval(async () => {
          const responseJob = await partsStore.getJob(newJobPathKey!)
          const jobOutput = responseJob?.jobOutputs.items.find(
            (output) => output.analysisId === 1 && output.objectiveId === 0
          )
          if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.COMPLETE) {
            partsStore.setSelectedJobOutput(jobOutput)
            moveToNextStep({ jobPathKey: newJobPathKey, outputPathKey: jobOutput.pathKey })
            clearInterval(checkIsJobFinished)
            preloaderState.hidePreloader()
          }
          if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.ERROR) {
            console.error('Unexpected error !')
            clearInterval(checkIsJobFinished)
            preloaderState.hidePreloader()
          }
        }, 4000)
        break
      }
      case JOB_STEP.GENERATE_SUPPORTS: {
        break
      }
      default: {
        moveToNextStep({ pathKey: params.pathKey, jobPathKey: params.jobPathKey, outputPathKey: params.outputPathKey })
      }
    }
  }

  return (
    <div className='step-navigation'>
      <StepButton onClick={stepAction}>{nextStep?.name}</StepButton>
    </div>
  )
}

export default observer(ChangeStepButton)
