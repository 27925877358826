import { Object3D, PerspectiveCamera, Vector3 } from 'three'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'

export class ControlledCamera {
  camera: PerspectiveCamera
  canvas: HTMLCanvasElement
  fov: number
  aspect: number
  position: Vector3
  controls: TrackballControls | null
  defaultPosition: [number, number, number] = [0, 0, 0]
  objSize: [number, number, number] = [162, 16, 59]

  constructor(canvas: HTMLCanvasElement, fov = 40) {
    Object3D.DefaultUp.set(0, 0, 1)
    Object3D.DefaultMatrixAutoUpdate = true
    this.canvas = canvas
    this.fov = fov
    this.aspect = canvas.width / canvas.height
    this.camera = new PerspectiveCamera(fov, this.aspect, 25, 5000)
    this.position = this.camera.position
    this.controls = null
  }
  // getInitPosition(group: Object3D) {
  //   const bbox = new Box3().setFromObject(group)
  //   const sphere = bbox.getBoundingSphere(new Sphere())
  //   const radius = sphere.radius
  //   const angle = 90 - this.fov * 0.5
  //   const halfOfView = radius + radius * 0.2
  //   const dist = Math.tan(angle) * halfOfView
  // }

  initPosition([x, y, z] = [127, -100, 25]) {
    this.controls && this.controls.reset()
    // this.camera.position.set(w * 0.9, l * -1.2, h)
    this.camera.position.set(x, y, z)
  }
  setObjSize([w, h, l]: [number, number, number]) {
    this.objSize = [w, h, l]
  }
  updateSecondCamera(camera: ControlledCamera, scenePosition: Vector3) {
    this.camera.position.copy(camera.camera.position)
    camera.controls && this.camera.position.sub(camera.controls.target)
    this.camera.position.setLength(150)
    this.camera.lookAt(scenePosition)
  }
  setPosition({ x, y, z }: Vector3) {
    this.camera.position.set(x, y, z)
  }
  addControl() {
    this.controls = new TrackballControls(this.camera, this.canvas)
    this.controls.rotateSpeed = 2
    this.controls.zoomSpeed = 1
    this.controls.panSpeed = 1
    this.controls.noZoom = true
    this.controls.noPan = true
    this.controls.noRotate = true
    this.controls.dynamicDampingFactor = 0.3
    this.controls.enabled = true
    return this.controls
  }
  destroy() {
    this.camera.remove()
  }
}
