import { FC } from 'react'
import './GeneralButton.scss'

export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  SUBMIT = 'submit'
}

type GeneralButtonProps = {
  onClick: () => void
}

const GeneralButton: FC<GeneralButtonProps> = ({ children, onClick }) => {
  return (
    <button onClick={onClick} className='general-button'>
      {children}
    </button>
  )
}

export default GeneralButton
