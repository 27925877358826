const SettingsIcon = () => {
  return (
    <svg viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#prefix__clip0)' strokeWidth={1.3} strokeLinecap='round' strokeLinejoin='round'>
        <path d='M8.586 10a2 2 0 100-4 2 2 0 000 4z' />
        <path d='M13.52 10.001a1.1 1.1 0 00.22 1.214l.04.04a1.333 1.333 0 11-1.887 1.886l-.04-.04a1.1 1.1 0 00-1.213-.22 1.1 1.1 0 00-.667 1.007v.113a1.333 1.333 0 11-2.667 0v-.06a1.1 1.1 0 00-.72-1.006 1.1 1.1 0 00-1.213.22l-.04.04a1.333 1.333 0 01-2.176-1.454c.067-.162.165-.31.29-.433l.04-.04a1.1 1.1 0 00.22-1.213A1.1 1.1 0 002.7 9.388h-.114a1.333 1.333 0 010-2.667h.06a1.1 1.1 0 001.007-.72 1.1 1.1 0 00-.22-1.213l-.04-.04A1.333 1.333 0 115.28 2.861l.04.04a1.1 1.1 0 001.213.22h.053a1.1 1.1 0 00.667-1.006V2a1.333 1.333 0 012.667 0v.06a1.1 1.1 0 00.666 1.007 1.1 1.1 0 001.214-.22l.04-.04a1.333 1.333 0 111.886 1.887l-.04.04a1.1 1.1 0 00-.22 1.213v.053a1.1 1.1 0 001.007.667h.113a1.333 1.333 0 010 2.667h-.06A1.1 1.1 0 0013.52 10v0z' />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' transform='translate(.586)' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SettingsIcon
