import { FC, useState } from 'react'
import HandIcon from '../../Icons/Hand'
import Reset from '../../Icons/Reset'
import ZoomIcon from '../../Icons/Zoom'

import './ViewerButtons.scss'

type ViewerButtonsProps = { onClick: (value: boolean) => void }
type ButtonWrapProps = ViewerButtonsProps & { selectable?: boolean; id: string }

const ButtonWrap: FC<ButtonWrapProps> = ({ onClick, selectable, children, id }) => {
  const [selected, setSelected] = useState(false)

  const click = () => {
    selectable && setSelected(!selected)
    onClick && onClick(!selected)
  }

  return (
    <div className={`viewer-button ${selected ? 'selected' : ''}`} onClick={click} id={id}>
      {children}
    </div>
  )
}
ButtonWrap.defaultProps = {
  selectable: true
}

export const ZoomViewerBtn = ({ onClick }: ViewerButtonsProps) => {
  return (
    <ButtonWrap onClick={onClick} id={'viewer-btn-zoom'}>
      <ZoomIcon />
    </ButtonWrap>
  )
}

export const HandViewerBtn = ({ onClick }: ViewerButtonsProps) => {
  return (
    <ButtonWrap onClick={onClick} id={'viewer-btn-hand'}>
      <HandIcon />
    </ButtonWrap>
  )
}

export const ResetViewerBtn = ({ onClick }: ViewerButtonsProps) => {
  return (
    <ButtonWrap onClick={onClick} selectable={false} id={'viewer-btn-reset'}>
      <Reset />
    </ButtonWrap>
  )
}
