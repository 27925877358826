import { TPrinterConfig } from './../../../store/jobConfig/types'
import {
  LineBasicMaterial,
  EdgesGeometry,
  BoxBufferGeometry,
  LineSegments,
  MeshBasicMaterial,
  MeshPhongMaterial,
  Group,
  DoubleSide,
  Mesh,
  Object3D
} from 'three'

const grayColor = 0xd6d6d6
const getEdges = (width: number, length: number, height: number) => {
  const edgesMaterial = new LineBasicMaterial({ color: grayColor })
  const geometry = new EdgesGeometry(new BoxBufferGeometry(width + 0.01, length + 0.01, height + 0.01))
  return new LineSegments(geometry, edgesMaterial)
}

export class Printer {
  group: Group = new Group()
  config: TPrinterConfig
  width: number
  height: number
  length: number
  thickness: number
  hsub: number

  constructor(config: TPrinterConfig) {
    Object3D.DefaultUp.set(0, 0, 1)
    Object3D.DefaultMatrixAutoUpdate = true

    this.config = config
    this.width = this.config.plateX
    this.length = this.config.plateY
    this.height = this.config.plateZ
    this.thickness = this.config.thickness / 4
    this.hsub = this.thickness / 2
  }

  addChamber() {
    const plateMaterial = new MeshBasicMaterial({
      color: 0xd9ead3,
      side: DoubleSide
    })
    const bordersMaterial = new MeshPhongMaterial({
      color: grayColor,
      side: DoubleSide,
      transparent: true,
      opacity: 0.1
    })
    const bordersGeometry = new BoxBufferGeometry(this.width, this.length, this.height)
    const buildPlateGeometry = new BoxBufferGeometry(this.width, this.length, this.thickness)
    const borders = new Mesh(bordersGeometry, bordersMaterial)
    const buildPlate = new Mesh(buildPlateGeometry, plateMaterial)
    const plateEdges = getEdges(this.width, this.length, this.thickness)
    const bordersEdges = getEdges(this.width, this.length, this.height)
    bordersEdges.position.z = (this.height + this.thickness) / 2
    borders.position.z = (this.height + this.thickness) / 2
    this.group.position.z = -this.thickness / 2
    this.group.add(buildPlate, borders, plateEdges, bordersEdges)
  }
  getSize() {
    return [this.width, this.length, this.height] as [number, number, number]
  }
}
