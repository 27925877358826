import LayoutWithProgress from '../layouts/LayoutWithJobProgress'

import PartsList from '../pages/PartsList'
import TagSurface from '../pages/TagSurface'
import GenerateSupports from '../pages/GenerateSupports'
import DesignSupport from '../pages/DesignSupport'
import ModifySupports from '../pages/ModifySupports'
import OrientationType from '../pages/OrientationType'
import OptimizerOptions from '../pages/OptimizerOptions'
import ManualOrientation from '../pages/ManualOrientation'

import { IRouteConfig, ROUTES } from './types'

const routes: IRouteConfig[] = [
  {
    path: ROUTES.MAIN,
    component: LayoutWithProgress,
    routes: [
      {
        path: ROUTES.PARTS_LIST,
        component: PartsList
      },
      {
        path: ROUTES.TAG_SURFACE,
        component: TagSurface
      },
      {
        path: ROUTES.ORIENTATION_TYPE,
        component: OrientationType
      },
      {
        path: ROUTES.OPTIMIZER_OPTIONS,
        component: OptimizerOptions
      },
      {
        path: ROUTES.MANUAL_ORIENTATION,
        component: ManualOrientation
      },
      {
        path: ROUTES.DESIGN_SUPPORT,
        component: DesignSupport
      },
      {
        path: ROUTES.GENERATE_SUPPORTS,
        component: GenerateSupports
      },
      {
        path: ROUTES.MODIFY_SUPPORTS,
        component: ModifySupports
      }
    ]
  }
]

export default routes
