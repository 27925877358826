import { TSourceFile } from '../../types/files'
import { TJobConfig } from '../jobConfig/types'

export type TAllowedTypeToSelectedPart = TSourceFile | null

export type TPartLoaders = {
  delete: {
    inProgress: boolean
    pathKeys: string[]
  }
  fetch: {
    inProgress: boolean
    isLoaded: boolean
  }
  upload: {
    inProgress: boolean
  }
}

export enum JOB_OUTPUT_STATUS {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING'
}

export type TOutputFile = {
  id: string
  name: string
  pathKey: string
}

export type TOutputFilesToViewer = TOutputFile & { url?: string }

export type TJobOutput = {
  id: string
  analysisId: number
  objectiveId: number
  pathKey: string
  progress: number
  job: {
    sourceFile: {
      pathKey: string
    }
  }
  status: string
  outputFiles: {
    items: TOutputFile[]
  }
}

export type TJobOutputResponse = {
  data: {
    jobOutput: {
      items: TJobOutput[]
    }
  }
}

export type TJob = {
  id: string
  pathKey: string
  config: string
  createdAt: string
  updatedAt: string
  sourceFile: TSourceFile
  jobOutputs: {
    items: TJobOutput[]
  }
}

export type TJobResponse = {
  data: { Job: { items: [TJob] } }
}

export const partLoaders: TPartLoaders = {
  delete: {
    inProgress: false,
    pathKeys: []
  },
  fetch: {
    inProgress: false,
    isLoaded: false
  },
  upload: {
    inProgress: false
  }
}

export type TGraphqlPartsResponse = {
  data: {
    user: {
      sourceFiles: TSourceFile[]
    }
  }
}
export type TGenerateSupport = { url: string }

export type TUploadFilePayload = {
  file: File
  sourceFilePathKey?: string
  jobPathKey?: string
}
export type TGenerateSuppPayload = { sourceFilePathKey: string; config: TJobConfig }
export type TResponseUploadFile = {
  filePathKey: string
  filename: string
  url: string
}

export type TGetDownloadUrlPayload = {
  filename: string
  sourceFilePathKey: string
  jobPathKey?: string
  jobOutputPathKey?: string
}

export type TGetDownloadUrlResponse = {
  url: string
}

export type TCreateJobPayload = {
  sourceFilePathKey: string
  config: TJobConfig
}

export type TCreateJobResponse = {
  jobPathKey: string
}

export type TUpdateJobPayload = {
  jobPathKey: string
  config: TJobConfig
  jobDescription?: string
}

export type TSubmitJobPayload = {
  jobPathKey: string
  analysisId: number
  objectiveId: number
  outputFilePathKey?: string
  filename?: string
}

export type TSubmitJobResponse = ''
