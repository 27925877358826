import { Vector2, WebGLRenderer, WebGLRendererParameters } from 'three'

export class Renderer extends WebGLRenderer {
  container?: HTMLElement
  opacity: number
  color: number

  constructor(params: WebGLRendererParameters, id: string, opacity = 1, color = 0xfffff) {
    super(params)
    this.container = document.getElementById(id) || undefined
    this.opacity = opacity
    this.color = color

    this.init()
  }

  init() {
    this.setClearColor(this.color, this.opacity)
    this.container && this.container.appendChild(this.domElement)
    let { clientWidth, clientHeight } = this.domElement
    this.setSize(clientWidth, clientHeight, false)
    this.setPixelRatio(window.devicePixelRatio)
  }

  getNormCoord(event: MouseEvent) {
    if (!this.container) return new Vector2(0, 0)
    const { left, top } = this.container.getClientRects()[0]
    const x = ((event.pageX - left) / this.container.clientWidth) * 2 - 1
    const y = (-(event.pageY - top) / this.container.clientHeight) * 2 + 1
    return new Vector2(x, y)
  }
}
