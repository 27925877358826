export const partners = [
  {
    name: 'Materialise'
  },
  {
    name: 'SIEMENS'
  },
  {
    name: '3DXpert'
  },
  {
    name: 'Quant AM'
  }
]
