import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import setupAxiosInterceptors from './middlewares/setupAxios'
import reportWebVitals from './reportWebVitals'

import { history } from './store/router'

import './assets/styles/main.scss'
import './assets/styles/normalize.css'
import './assets/styles/skeleton.css'

setupAxiosInterceptors()

const render = () => {
  const App = require('./router').default

  ReactDOM.render(
    <React.StrictMode>
      <Router history={history}>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./router', render)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
