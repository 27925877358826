import { ChangeEvent, FC, useState } from 'react'

import './SelectInput.scss'

export type TSelectOption = {
  optionLabel: string
  value: string
}

type SelectInputProps = {
  onChange: (value: any) => void
  label: string
  options: TSelectOption[]
  value?: string
  isContainsEmpty?: boolean
}

const SelectInput: FC<SelectInputProps> = ({ onChange, label, options, value, isContainsEmpty }) => {
  const [val, setValue] = useState(value)
  const onInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    onChange(value)
    setValue(value)
  }

  return (
    <div className='general-select-input general-input-style'>
      <div className='general-select-input__label'>{label}</div>
      <select onChange={onInputChange} className='general-select-input__input' value={val}>
        {isContainsEmpty && (
          <option key={`empty-${Math.random()}`} value={'empty'}>
            Select one
          </option>
        )}
        {options.map((option) => (
          <option key={`${label}-${Math.random()}`} value={option.value}>
            {option.optionLabel}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectInput
