import { FC } from 'react'
import './StepButton.scss'

type NextStepButtonProps = {
  onClick: () => void
}

const NextStepButton: FC<NextStepButtonProps> = ({ children, onClick }) => {
  return (
    <button onClick={onClick} className='next-step-button'>
      <div className='next-step-button__direction'>Next</div>
      <div className='next-step-button__step'>{children}</div>
    </button>
  )
}

export default NextStepButton
