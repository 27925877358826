import { ChangeEvent, FC } from 'react'

import './NumberInput.scss'

type NumberInputProps = {
  onChange: (value: [number]) => void
  label: string
  value: number
}

const NumberInput: FC<NumberInputProps> = ({ onChange, label, value }) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: [number] = [Number(event.target.value)]
    onChange(value)
  }
  return (
    <div className='general-number-input general-input-style'>
      <div className='general-number-input__label'>{label}</div>
      <input value={value} onChange={onInputChange} type='number' className='general-number-input__input' />
    </div>
  )
}

export default NumberInput
