import SUPP_BLOCK_TEETH from '../../assets/img/supp_block_teeth.png'
import SUPP_CONE from '../../assets/img/supp_cone.png'
import SUPP_LINE_TEETH from '../../assets/img/supp_line_teeth.png'
import SUPP_TREE from '../../assets/img/supp_tree.png'
import SUPP_VOLUME_FRAGMENT from '../../assets/img/supp_volume_fragmentation.png'
import SUPP_VOLUME from '../../assets/img/supp_volume.png'
import {
  DESIGN_SUPPORT,
  PRIMARY_ATTACHMENT_GEOMETRY,
  STRENG_ATTACHMENT_GEOMETRY,
  STRENG_DESIGN_SUPPORTS,
  TEmptySuppField
} from '../../types/job'

export const SUPPORT_PREVIEWS = {
  [DESIGN_SUPPORT.BLOCK]: {
    [PRIMARY_ATTACHMENT_GEOMETRY.TOOTH]: SUPP_BLOCK_TEETH,
    [TEmptySuppField]: SUPP_BLOCK_TEETH
  },
  [DESIGN_SUPPORT.LINE]: {
    [PRIMARY_ATTACHMENT_GEOMETRY.TOOTH]: SUPP_LINE_TEETH,
    [TEmptySuppField]: SUPP_LINE_TEETH
  },
  [DESIGN_SUPPORT.PERFORATED_VOLUME]: {
    [PRIMARY_ATTACHMENT_GEOMETRY.TOOTH]: SUPP_VOLUME_FRAGMENT,
    [TEmptySuppField]: SUPP_VOLUME_FRAGMENT
  }
}

export const SUPPORT_PREVIEWS_STRENGTHENED = {
  [STRENG_DESIGN_SUPPORTS.CONE]: {
    [STRENG_ATTACHMENT_GEOMETRY.SAME_AS_SUPPORT]: SUPP_CONE,
    [STRENG_ATTACHMENT_GEOMETRY.TREE]: SUPP_CONE,
    [TEmptySuppField]: SUPP_CONE
  },
  [STRENG_DESIGN_SUPPORTS.SOLID_VOLME]: {
    [STRENG_ATTACHMENT_GEOMETRY.SAME_AS_SUPPORT]: SUPP_VOLUME,
    [STRENG_ATTACHMENT_GEOMETRY.TREE]: SUPP_VOLUME,
    [TEmptySuppField]: SUPP_VOLUME
  },
  [STRENG_DESIGN_SUPPORTS.TREE]: {
    [STRENG_ATTACHMENT_GEOMETRY.SAME_AS_SUPPORT]: SUPP_TREE,
    [STRENG_ATTACHMENT_GEOMETRY.TREE]: SUPP_TREE,
    [TEmptySuppField]: SUPP_TREE
  }
}
