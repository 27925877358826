import { TAG } from '../../store/viewer/types'
import viewerStore from '../../store/viewer'

import './Checkbox.scss'

type CheckboxProps = {
  checkBoxTag: TAG
  onChoose: (tag: TAG) => void
}

const Checkbox = ({ checkBoxTag, onChoose }: CheckboxProps) => {
  const { tag, color, value } = checkBoxTag
  const { currentTag } = viewerStore

  const click = () => onChoose(checkBoxTag)

  const isSelected = currentTag.tag === tag

  return (
    <div
      className={`tag-surface-page__form__item faces-radio-input ${isSelected ? 'selected' : ''}`}
      style={{
        background: isSelected ? color : 'initial',
        borderColor: color
      }}
      onClick={click}
    >
      <div className='faces-radio-input__mark' style={{ background: color }} />
      <div className='faces-radio-input__label' style={{ color: !isSelected ? color : 'white' }}>
        {value}
      </div>
    </div>
  )
}

Checkbox.defaultProps = {
  selected: false
}

export default Checkbox
