import React, { FC, useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Preloader from '../components/GlobalPreloader'

import RouteWithSubRoute from '../components/Routes/RouteWithSubRoutes'

import jobConfigStore from '../store/jobConfig'
import partsStore from '../store/parts'
import preloaderStore from '../store/preloader'

import routes from './routes'
import { ROUTES } from './types'

const Router: FC<{}> = () => {
  useEffect(() => {
    const getPrinterConfig = async () => {
      preloaderStore.showPreloader()
      await jobConfigStore.getPrintConfig()
      preloaderStore.hidePreloader()
    }
    getPrinterConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getParts = async () => {
      if (!partsStore.loaders.fetch.isLoaded) {
        preloaderStore.showPreloader()
        await partsStore.getParts()
        preloaderStore.hidePreloader()
      }
    }
    getParts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.loaders.fetch.isLoaded])

  return (
    <div className='app-container'>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoute key={i} {...route} />
        ))}
        <Redirect to={ROUTES.MAIN} />
      </Switch>
      <Preloader />
    </div>
  )
}

export default Router
