export enum SHORTCUT_OVERALL_STEP {
  ORDER_AND_DELIVERY = 'Order',
  DESIGN = 'Design',
  VALIDATION = 'validation',
  TOP_OP = 'Top-op',
  BUILD_PREPARATION = 'Build prep.',
  SCHEDULE = 'Schedule',
  PRODUCE = 'Produce',
  POST_PROCESS = 'Post-Process',
  QUALITY_AND_MONITORING = 'QA/QC'
}
