import { ITableHeaderCell, TABLE_COLUMN_ALIGN_TYPE } from '../../components/UI/Table'
import { SHORTCUT_OVERALL_STEP } from '../../types/overallSteps'

export const partTableHeadings: ITableHeaderCell[] = [
  { value: 'Step' },
  { value: 'Date complete' },
  { value: 'Application used', align: TABLE_COLUMN_ALIGN_TYPE.CENTER, width: 120 }
]

export const partTableMockedRows = [
  [
    {
      value: SHORTCUT_OVERALL_STEP.ORDER_AND_DELIVERY,
      isCompleted: true
    },
    {
      value: 'Aug 13 2021'
    },
    {
      value: 'Parametrs'
    }
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.DESIGN,
      isCompleted: false
    },
    null,
    null
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.VALIDATION,
      isCompleted: false
    },
    null,
    null
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.TOP_OP,
      isCompleted: false
    },
    null,
    null
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.BUILD_PREPARATION,
      isCompleted: true
    },
    {
      value: 'Aug 13 2021'
    },
    {
      value: 'Quant AM'
    }
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.SCHEDULE,
      isCompleted: true
    },
    {
      value: 'Aug 13 2021'
    },
    {
      value: 'Parametrs'
    }
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.PRODUCE,
      isCompleted: false
    },
    null,
    null
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.POST_PROCESS,
      isCompleted: false
    },
    null,
    null
  ],
  [
    {
      value: SHORTCUT_OVERALL_STEP.QUALITY_AND_MONITORING,
      isCompleted: true
    },
    {
      value: 'Aug 13 2021'
    },
    {
      value: 'SIEMENS'
    }
  ]
]
