import './Icons.scss'

const VerticalDots = () => {
  return (
    <div className='vertical-dots'>
      <div className='vertical-dots__item first'></div>
      <div className='vertical-dots__item second'></div>
      <div className='vertical-dots__item third'></div>
    </div>
  )
}

export default VerticalDots
