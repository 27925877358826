import {
  MATERIAL_NAME,
  PRINTER_TYPE,
  STRENG_DESIGN_SUPPORTS,
  DESIGN_SUPPORT,
  UNIT_TYPE,
  PRIMARY_ATTACHMENT_GEOMETRY,
  STRENG_ATTACHMENT_GEOMETRY,
  TEmptySuppField
} from '../../types/job'
import { TAG_CONFIG_FIELD } from '../viewer/types'

// TODO fill all fields
export type TJobConfig = {
  printer: PRINTER_TYPE
  filename: string
  RotationalAngle: [number, number, number]
  hsub: [number]
  scaling: [number]
  unit: UNIT_TYPE
  isSupport: [number]
  isStrengthSupp: boolean
  isRepair: boolean
  isCompensate: [number]
  elementsize: [number]
  tetraMeshSize: [number]
  tetraEps: [number]
  suppSpacing_xy: [number]
  supp_size_boundary: [number]
  suppHatchSize: [number]
  material: [number]
  material_name: MATERIAL_NAME
  isHeatTreatment: boolean
  isEvalFacet: boolean
  isEvalEdge: boolean
  isEvalVertex: boolean
  critical_angle: [number]
  [TAG_CONFIG_FIELD.RESTRICTED]: number[]
  [TAG_CONFIG_FIELD.UPSKIN]: number[]
  [TAG_CONFIG_FIELD.ALLOWED]: number[]
  isFragmentation: boolean
  fragmentationSize: [number]
  isMinPeeling: boolean
  CrashTolerance: [number]
  MaxDist: [number]
  Weights: [number, number, number, number]
  AngleRestriction: []
  filename_supp: string
  unit_supp: string
  N_part: []
  scalingX_material: [number]
  scalingY_material: [number]
  Speed_laser: [number]
  layerThickness: [number]
  Diameter_laser: [number]
  Power_laser: [number]
  NLaser: [number]
  material_buildPlate: [number]
  H_buildPlate: [number]
  Tplate: [number]
  fraction_avg: [number]
  Wt: [number]
  Pt: [number]
  Tt: [number]
  Ot: [number]
  Wb: [number]
  Pb: [number]
  Tb: [number]
  Ob: [number]
  PrinterXYZ: []
  DirectionRecoater: []
  rho: []
  Cp: []
  k: []
  Tsolid: []
  Tliquid: []
  hsl: []
  alpha: []
  E: []
  E_heat: []
  nu: []
  sigma_y: []
  sigma_y_heat: []
  AdditionalConfig: boolean
  needRemesh: boolean
  isStrengthening: 0 | 1
  // TODO investigate is this filed needed
  isAdditionalConfig?: boolean
}

export type TObjectiveFunctions = {
  min_dist: boolean
  optMaxDist: boolean
  cust_weig: boolean
}

export type TCustomWeights = {
  min_time: number
  min_material: number
  min_build_time: number
  min_dist_range: number
}

export const defaultObjectiveFunctions: TObjectiveFunctions = {
  min_dist: false,
  optMaxDist: false,
  cust_weig: false
}

export const defaultCustomWeights: TCustomWeights = {
  min_time: 0,
  min_material: 0,
  min_build_time: 0,
  min_dist_range: 0
}

export const defaultConfig: TJobConfig = {
  printer: PRINTER_TYPE.EOSM290,
  filename: 'B1_handle.stl',
  RotationalAngle: [0.0, 0.0, 0.0],
  hsub: [5.0],
  scaling: [1.0],
  unit: UNIT_TYPE.MILLIMETER,
  isSupport: [1],
  isStrengthSupp: true,
  isRepair: false,
  isCompensate: [0],
  elementsize: [2e-3],
  tetraMeshSize: [2],
  tetraEps: [1e-3],
  suppSpacing_xy: [1.0],
  supp_size_boundary: [0.6],
  suppHatchSize: [3.5],
  material: [1],
  material_name: MATERIAL_NAME.TI64,
  // AT DEFAULT CONFIG WAS FALSE
  isHeatTreatment: true,
  isEvalFacet: true,
  isEvalEdge: true,
  isEvalVertex: true,
  critical_angle: [40],
  [TAG_CONFIG_FIELD.RESTRICTED]: [],
  [TAG_CONFIG_FIELD.UPSKIN]: [],
  [TAG_CONFIG_FIELD.ALLOWED]: [],
  isFragmentation: false,
  fragmentationSize: [5.0],
  isMinPeeling: false,
  CrashTolerance: [0.4],
  MaxDist: [-1],
  Weights: [0.0, 0.0, 0.0, 1.0],
  AngleRestriction: [],
  filename_supp: '',
  unit_supp: '',
  N_part: [],
  scalingX_material: [0],
  scalingY_material: [0],
  Speed_laser: [500],
  layerThickness: [40],
  Diameter_laser: [100],
  Power_laser: [400],
  NLaser: [1],
  material_buildPlate: [0],
  H_buildPlate: [30],
  Tplate: [80.0],
  fraction_avg: [0.42],
  Wt: [0.5],
  Pt: [0.16],
  Tt: [0.5],
  Ot: [0.8],
  Wb: [0.8],
  Pb: [0.16],
  Tb: [0.5],
  Ob: [0.8],
  PrinterXYZ: [],
  DirectionRecoater: [],
  rho: [],
  Cp: [],
  k: [],
  Tsolid: [],
  Tliquid: [],
  hsl: [],
  alpha: [],
  E: [],
  E_heat: [],
  nu: [],
  sigma_y: [],
  sigma_y_heat: [],
  isStrengthening: 0,
  AdditionalConfig: false,
  needRemesh: false
}

export type TRoesponseGetConfig = {
  config: {
    materials: TMaterialConfig[]
    printers: TPrinterConfig[]
  }
}

export type TPrinterConfig = {
  diameter: number
  name: PRINTER_TYPE
  plateDiameter: number
  plateMode: number
  plateThickness: number
  plateX: number
  plateY: number
  plateZ: number
  power: number
  recoaterDir: number
  recoaterTwoWay: boolean
  speed: number
  thickness: number
  valid: boolean
}

export type TMaterialConfig = {
  materialEnum: number
  name: MATERIAL_NAME
  trademark: boolean
  valid: boolean
}

export type TSupportConfig = {
  supp_geom: DESIGN_SUPPORT
  str_supp_geom: STRENG_DESIGN_SUPPORTS
  attach_geom: PRIMARY_ATTACHMENT_GEOMETRY | typeof TEmptySuppField
  str_attach_geom: STRENG_ATTACHMENT_GEOMETRY | typeof TEmptySuppField
}

export const defaultSupportConfig: TSupportConfig = {
  supp_geom: DESIGN_SUPPORT.BLOCK,
  str_supp_geom: STRENG_DESIGN_SUPPORTS.SOLID_VOLME,
  attach_geom: TEmptySuppField,
  str_attach_geom: TEmptySuppField
}
