import { ChangeEvent, FC, useRef } from 'react'

import './FileInput.scss'

type FileInputProps = {
  onFileAdd: (data: any) => void
  accept: string
  inProgress: boolean
}

const FileInput: FC<FileInputProps> = ({ onFileAdd, children, accept, inProgress }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onButtonClick = () => {
    if (!inProgress) {
      inputRef.current?.click()
    }
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event.target.files
    if (inputFiles?.length) {
      onFileAdd(inputFiles[0])
    }
  }
  return (
    <div className='file-input general-input-style'>
      <button className='file-input__button' onClick={onButtonClick}>
        <input ref={inputRef} onChange={onChange} type='file' className='file-input__button__input' accept={accept} />
        {children}
      </button>
    </div>
  )
}

export default FileInput
