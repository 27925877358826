import { TJobConfig, TPrinterConfig } from './../../store/jobConfig/types'
import { Object3D, Raycaster, Scene, Vector2 } from 'three'
import { Printer } from './classes/printer'
import { Part } from './classes/part'
import { Helper } from './classes/helpers'
import { ControlledCamera } from './classes/camera'
import { Light } from './classes/light'
import { Renderer } from './classes/renderer'
import { TAG } from '../../store/viewer/types'
import { Supports } from './classes/support'
import { parseBinFile } from '../../components/Viewer/helper'
import viewerState from '../../store/viewer'

type FuncProps = { togglePanRotateBtn: (val: boolean) => void }

export class XViewer {
  renderer = new Renderer({ antialias: true, alpha: true }, 'x-viewer', 1, 0xf7f7f7)
  renderer2 = new Renderer({ antialias: true, alpha: true }, 'inset', 0)
  part: Part
  camera = new ControlledCamera(this.renderer.domElement)
  camera2 = new ControlledCamera(this.renderer2.domElement)
  controls = this.camera.controls
  printer: Printer
  scene = new Scene()
  scene2 = new Scene()
  ray = new Raycaster()
  supp = new Supports()
  cancelID = 0
  light = new Light()
  helper = new Helper()
  mouse = new Vector2()
  currentTag: TAG
  isMouseDown = false
  urls: string[]
  distortionConfig: any
  togglePanRotateBtn: (val: boolean) => void

  constructor(tag: TAG, urls: string[], printerConf: TPrinterConfig, { togglePanRotateBtn }: FuncProps) {
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseClick = this.onMouseClick.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.printer = new Printer(printerConf)

    this.part = new Part('part', this.printer.hsub)
    this.currentTag = tag
    this.distortionConfig = {}
    this.urls = urls
    this.togglePanRotateBtn = togglePanRotateBtn

    this.init(urls[0])
    this.animate()
  }

  init(partUrl: string) {
    Object3D.DefaultUp.set(0, 0, 1)
    Object3D.DefaultMatrixAutoUpdate = true

    const promises = [this.part.add(partUrl), this.printer.addChamber()]
    Promise.all(promises)
      .then(() => {
        this.controls = this.camera.addControl()
        this.camera.setObjSize(this.part.size)
        this.camera.initPosition()
        this.scene.add(this.camera.camera, this.part.group, this.light.group)
        this.scene2.add(this.camera2.camera, this.helper.axisGroup)
      })
      .then(() => viewerState.setLoading(false))

    this.renderer.domElement.addEventListener('mousemove', this.onMouseMove)
    this.renderer.domElement.addEventListener('click', this.onMouseClick)
    this.renderer.domElement.addEventListener('mouseup', this.onMouseUp)
    this.renderer.domElement.addEventListener('mousedown', this.onMouseDown)
  }

  animate() {
    this.cancelID = requestAnimationFrame(this.animate.bind(this))
    this.controls && this.controls.update()
    this.camera2.updateSecondCamera(this.camera, this.scene2.position)
    this.renderer.render(this.scene, this.camera.camera)
    this.renderer2.render(this.scene2, this.camera2.camera)
  }
  // removePart() {
  //   this.scene.remove(this.part.group)
  // }
  // addNewPart(url = this.urls[0]) {
  //   this.part = new Part()
  //   new Promise(() =>
  //     this.part.add(url).then(() => {
  //       this.scene.add(this.part.group)
  //       viewerState.setLoading(false)
  //     })
  //   )
  // }
  processDist(distData: ArrayBuffer) {
    this.distortionConfig = parseBinFile(distData, 0, this.part.mesh)
    this.part.setDistColor(this.distortionConfig.dispX)
  }

  //toggles
  toggleDistortion(value: boolean) {
    if (value && this.part.distConfig.length) {
      this.part.setDistColor()
    } else {
      this.part.setColor()
    }
  }
  toggleBuildChamber(value: boolean) {
    if (value) {
      this.scene.add(this.printer.group)
      this.camera.initPosition([200, -200, 90])
      this.part.lift()
      this.scene.add(this.printer.group)
    } else {
      this.scene.remove(this.printer.group)
      this.camera.setObjSize(this.part.size)
      this.camera.initPosition()
      this.part.down()
    }
  }
  toggleSupport(value: boolean) {
    if (value) {
      if (this.urls[1]) {
        new Promise(() =>
          this.supp.add(this.urls[1]).then(() => {
            this.scene.add(this.supp.group)
            this.part.lift(this.supp.height)
            this.part.centerGroup()
            viewerState.setLoading(false)
          })
        )
      }
    } else {
      this.scene.remove(this.supp.group)
      this.part.down()
      viewerState.setLoading(false)
    }
  }
  toggleZoom(value: boolean) {
    if (!this.controls) return
    this.controls.noZoom = !value
  }
  togglePanRotate(value: boolean) {
    if (!this.controls) return
    this.controls.noPan = !value
    this.controls.noRotate = !value
  }
  toggleOrientation(value: boolean) {
    this.part.isRotationMode = value
  }
  toggleTagging(value: boolean) {
    this.part.isTagMode = value
    const isSomeFaceSelected = !!Object.keys(this.part.selectedFaces).length
    if (!isSomeFaceSelected) return

    value && this.part.colorAllSelected()
    !value && this.part.setColor()
  }

  //resets
  resetCamera() {
    this.camera.initPosition()
  }
  resetTags() {
    this.part.resetFaces()
  }

  //changes
  applyInputRotation(angles: TJobConfig['RotationalAngle']) {
    this.part.setDegRotation(angles)
  }
  applyInputScaling(scaling: TJobConfig['scaling']) {
    this.part.setScaling(scaling)
  }
  setCurrentTag(currentTag: TAG) {
    this.currentTag = currentTag
  }

  //mouse
  getIntersects(event: MouseEvent) {
    this.mouse = this.renderer.getNormCoord(event)
    this.ray.setFromCamera(this.mouse, this.camera.camera)
    return this.ray.intersectObject(this.part.group, true)
  }
  onMouseUp() {
    if (this.isMouseDown) {
      this.part.setConfToStore()
      this.isMouseDown = false
    }
  }
  onMouseMove(event: MouseEvent) {
    if (!this.part.isTagMode || !this.currentTag) return
    this.part.setFaceColor(this.part.prevFace.face, this.part.prevFace.color)

    const intrs = this.getIntersects(event)
    if (!intrs[0] || !intrs.length || !intrs[0].face || !intrs[0].faceIndex) return
    const { face, faceIndex } = intrs[0]
    if (this.isMouseDown) {
      if (this.controls && !this.controls.noPan) {
        this.togglePanRotateBtn(false)
        this.togglePanRotate(false)
      }
      this.part.colorFaceOnClick(faceIndex, face, this.currentTag)
    } else {
      this.part.colorFaceOnMove(faceIndex, face, this.currentTag.color)
    }
  }
  onMouseDown(event: MouseEvent) {
    const intrs = this.getIntersects(event)
    if (!intrs[0] || !intrs.length || !intrs[0].face || !intrs[0].faceIndex) return
    this.isMouseDown = true
  }
  onMouseClick(event: MouseEvent) {
    if (!this.part.isTagMode || !this.currentTag) return
    const intrs = this.getIntersects(event)
    if (!intrs[0] || !intrs.length || !intrs[0].face || !intrs[0].faceIndex) return
    const { face, faceIndex } = intrs[0]
    this.part.colorFaceOnClick(faceIndex, face, this.currentTag)
  }

  //destroy
  destroy() {
    cancelAnimationFrame(this.cancelID)
    this.scene.remove()
    this.scene2.remove()
    this.camera.destroy()
    this.camera2.destroy()

    function empty(elem: Element) {
      while (elem.lastChild) elem.removeChild(elem.lastChild)
      elem.remove()
    }
    empty(this.renderer.domElement)
    empty(this.renderer2.domElement)

    this.renderer.domElement.removeEventListener('mousemove', this.onMouseMove)
    this.renderer.domElement.removeEventListener('click', this.onMouseClick)
    this.renderer.domElement.removeEventListener('mouseup', this.onMouseUp)
    this.renderer.domElement.removeEventListener('mousedown', this.onMouseDown)

    this.renderer.dispose()
    this.renderer2.dispose()
  }
}
