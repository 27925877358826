import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import ResetBtn from '../../components/UI/Buttons/ResetButton'

import stepStore from '../../store/jobProgress'
import { JOB_STEP } from '../../store/jobProgress/types'
import viewerStore from '../../store/viewer'
import configStore from '../../store/jobConfig'
import partsStore from '../../store/parts'
import preloaderStore from '../../store/preloader'

import { TAG } from '../../store/viewer/types'
import Checkbox from '../../components/TagsCheckbox'

import './TagSurface.scss'
import { useRouteMatch } from 'react-router'

const TagSurface: FC = () => {
  const {
    params: { pathKey }
  } = useRouteMatch<{ pathKey: string }>()
  const { setStep } = stepStore
  const tags = viewerStore.tags
  const [currentTag, setCurrentTag] = useState<TAG>(tags[0])
  const { resetIFaces } = configStore
  const isCurrentTagNull = !viewerStore.currentTag

  useEffect(() => {
    setStep(JOB_STEP.TAG_SURFACE)
  }, [setStep])

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  useEffect(() => {
    const selectedPart = partsStore.selectedPart
    const getDownloadUrls = async () => {
      if (selectedPart && !viewerStore.fileUrls.length) {
        preloaderStore.showPreloader()
        const urls = await partsStore.getDownloadURL({
          filename: selectedPart.name,
          sourceFilePathKey: selectedPart.pathKey
        })
        viewerStore.setFilesUrls([urls])
        preloaderStore.hidePreloader()
      }
    }
    getDownloadUrls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedPart])

  useEffect(() => {
    viewerStore.setCurrentTag(currentTag)
  }, [currentTag, isCurrentTagNull])

  const onChoose = (tag: TAG) => setCurrentTag(tag)

  const reset = () => resetIFaces()

  return (
    <div className='tag-surface-page general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Tag Surfaces</div>
        <div className='general-page-style__content__left-block tag-surface-page__content'>
          <div className='tag-surface-page__form'>
            {tags.map((tag, index) => (
              <Checkbox checkBoxTag={tag} key={`radio-${index}`} onChoose={onChoose} />
            ))}
            <div className='tag-surface-page__form__action'>
              <ResetBtn onClick={reset} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(TagSurface)
