import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'

import OpenWith from '../../components/OpenWith'
import PaginatedList from '../../components/PaginatedList'
import PartIListtem from '../../components/PartIListtem'
import Table from '../../components/UI/Table'

import partsStore from '../../store/parts'
import routing from '../../store/router'
import JobProgressStepsState from '../../store/jobProgress'
import preloaderStore from '../../store/preloader'
import viewerStore from '../../store/viewer'

import { partTableHeadings, partTableMockedRows } from './constants'

import './PartsList.scss'
import FileInput from '../../components/UI/Inputs/FileInput'
import SpinnerIcon from '../../components/UI/Icons/Spinner'
import { generatePath } from 'react-router'
import { ROUTES } from '../../router/types'

interface PartsListProps {}

const PartsList: FC<PartsListProps> = () => {
  const { list, selectedPart, setSelectedPart, uploadFile, deleteFile, loaders } = partsStore

  useEffect(() => {
    JobProgressStepsState.setDefaultState()
  }, [])

  const onFileAdd = async (data: File) => {
    uploadFile({ file: data })
  }

  const onSubmitFile = async () => {
    preloaderStore.showPreloader()
    if (selectedPart) {
      const urls = await partsStore.getDownloadURL({
        filename: selectedPart.name,
        sourceFilePathKey: selectedPart.pathKey
      })
      viewerStore.setFilesUrls([urls])
      routing.push(
        generatePath(ROUTES.TAG_SURFACE, {
          pathKey: selectedPart.pathKey
        })
      )
    }
    preloaderStore.hidePreloader()
  }

  return (
    <div className='parts-page general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Parts</div>
        <div className='general-page-style__content__left-block parts-page__content'>
          <div className='parts-page__list'>
            <PaginatedList>
              {list.map((part) => {
                const isSelected = part.id === selectedPart?.id
                return (
                  <div
                    key={`part-item-${part.id}`}
                    onClick={() => setSelectedPart(part.pathKey)}
                    className={`parts-page__list__item ${isSelected && 'selected'}`}
                  >
                    <div className='mark' />
                    <PartIListtem
                      part={part}
                      isSelected={isSelected}
                      deleteFile={() => deleteFile(part.pathKey)}
                      isDeleting={loaders.delete.inProgress && loaders.delete.pathKeys.includes(part.pathKey)}
                    />
                  </div>
                )
              })}
            </PaginatedList>
          </div>
          <div className='parts-page__actions'>
            {/* <FileInput
              onFileAdd={onFileAdd}
              inProgress={loaders.upload.inProgress}
              accept='.stl'
            >
              {loaders.upload.inProgress && <SpinnerIcon />}
              Upload PRT file
            </FileInput> */}
            <FileInput inProgress={loaders.upload.inProgress} onFileAdd={onFileAdd} accept='.stl'>
              {loaders.upload.inProgress && <SpinnerIcon />}
              Upload file
            </FileInput>
          </div>
        </div>
        <div className='general-page-style__content__right-block'>
          <div className='parts-page__data-table'>
            <Table headings={partTableHeadings} rows={partTableMockedRows} />
          </div>
          <OpenWith onItemClick={onSubmitFile} isDisabled={!selectedPart} />
        </div>
      </div>
    </div>
  )
}
export default observer(PartsList)
