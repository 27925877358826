import LoadableImage from '../UI/LoadableImage'
import './SupportPreview.scss'

type SupportPreviewProps = {
  name: string
  support: string
}

const SupportPreview = ({ name, support }: SupportPreviewProps) => {
  return (
    <div className='support-preview'>
      <div className='support-preview__name'>{name}</div>
      <div className='support-preview__image'>
        <LoadableImage url={support} />
      </div>
    </div>
  )
}

export default SupportPreview
