import { AmbientLight, DirectionalLight, Group } from 'three'

const light = (position: [number, number, number], int?: number) => {
  const l = new DirectionalLight(0xffffff)
  l.position.set(...position).normalize()
  int && (l.intensity = int)
  return l
}

export class Light {
  group: Group = new Group()
  dist: number

  constructor(dist = 500) {
    this.dist = dist

    this.init()
  }

  init() {
    const l1 = light([this.dist, 2 * this.dist, this.dist])
    const l2 = light([-1 * this.dist, -1 * this.dist, 0.5 * this.dist], 1.5)
    const l3 = light([-0.3 * this.dist, -0.6 * this.dist, 0.8 * this.dist])
    const l4 = light([this.dist, 0.6 * this.dist, 0], 1.2)

    const al = new AmbientLight(0xffffff, 0.5)
    al.position.normalize()
    this.group.add(l1, l2, l3, al, l4)
  }
}
