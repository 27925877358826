import { FC } from 'react'

import VerticalDots from '../../UI/Icons/VerticalDots'

import './ProgressStepMark.scss'

type ProgressStepMarkProps = {
  text?: string | number
  isLast: boolean
  isCompleted: boolean
  isActive: boolean
  isNextStep: boolean
}

const ProgressStepMark: FC<ProgressStepMarkProps> = ({ text, isLast, isCompleted, isNextStep, isActive }) => {
  return (
    <div
      className={`overall-progress-marker
            ${text && 'contain-text'}
            ${isCompleted && 'completed'}
            ${isActive && 'active'}
            ${isNextStep && 'next-step'}
        `}
    >
      <div className='overall-progress-marker__circle'>{text}</div>
      {!isLast && (
        <div className='overall-progress-marker__step-line'>
          {isCompleted ? <div className='overall-progress-marker__step-line__line' /> : <VerticalDots />}
        </div>
      )}
    </div>
  )
}

export default ProgressStepMark
