import { FC } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { Route, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import ProgressBar from '../../components/ProgressBar'
import OverallJobProgress from '../../components/OverallJobProgress'
import RouteWithSubRoute from '../../components/Routes/RouteWithSubRoutes'
import ChangeStepButton from '../../components/ChangeStepButton'
import Viewer from '../../components/Viewer'

import MainLayout from '../MainLayout'

import { IRouteConfig, ROUTES } from '../../router/types'

import './LayoutWithJobProgress.scss'

type LayoutWithJobProgressProps = {
  routes: IRouteConfig[]
  history: RouteComponentProps['history']
  match: RouteComponentProps['match']
}

const LayoutWithJobProgress: FC<LayoutWithJobProgressProps> = ({ routes, ...restProps }) => {
  const pagesToDisplayViewer = [
    ROUTES.TAG_SURFACE,
    ROUTES.ORIENTATION_TYPE,
    ROUTES.OPTIMIZER_OPTIONS,
    ROUTES.MANUAL_ORIENTATION,
    ROUTES.DESIGN_SUPPORT,
    ROUTES.GENERATE_SUPPORTS,
    ROUTES.MODIFY_SUPPORTS
  ]
  const pagesToDisplayActionButtons = [
    ROUTES.TAG_SURFACE,
    ROUTES.MANUAL_ORIENTATION,
    ROUTES.DESIGN_SUPPORT,
    ROUTES.GENERATE_SUPPORTS,
    ROUTES.MODIFY_SUPPORTS
  ]

  return (
    <MainLayout>
      <OverallJobProgress />
      <div className='progress-layout'>
        <div className='progress-layout__bar'>
          <ProgressBar />
        </div>
        <div className='progress-layout__step-content'>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoute key={`${route.path}`} {...restProps} {...route} />
            ))}
            <Redirect to={ROUTES.PARTS_LIST} />
          </Switch>
          <Switch>
            <Route
              path={pagesToDisplayViewer}
              render={() => (
                <>
                  <div className='progress-layout__step-content__viewer'>
                    <Viewer />
                    <Switch>
                      <Route
                        path={pagesToDisplayActionButtons}
                        render={() => (
                          <div className='progress-layout__step-content__actions'>
                            <ChangeStepButton history={restProps.history} />
                          </div>
                        )}
                      />
                    </Switch>
                  </div>
                </>
              )}
            />
          </Switch>
        </div>
      </div>
    </MainLayout>
  )
}

export default observer(LayoutWithJobProgress)
