import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import preloaderState from '../../store/preloader'

import './Preloader.scss'

const Preloader: FC = () => {
  const {
    preloader: { isShow: isShowPreloader }
  } = preloaderState

  return isShowPreloader ? (
    <div className={`global-preloader ${isShowPreloader && 'active'}`}>
      <div className='transparent-preloader__spinner' />
    </div>
  ) : null
}

export default observer(Preloader)
