import { FC, useEffect } from 'react'

import stepStore from '../../store/jobProgress'
import configState from '../../store/jobConfig'
import viewerStore from '../../store/viewer'
import partsStore from '../../store/parts'
import { JOB_STEP } from '../../store/jobProgress/types'

import './DesignSupport.scss'
import SelectInput from '../../components/UI/Inputs/Select'
import { observer } from 'mobx-react-lite'
import {
  STRENG_DESIGN_SUPPORTS,
  DESIGN_SUPPORT,
  PRIMARY_ATTACHMENT_GEOMETRY,
  STRENG_ATTACHMENT_GEOMETRY
} from '../../types/job'
import { TSupportConfig } from '../../store/jobConfig/types'
import { useRouteMatch } from 'react-router'

import SupportPreview from '../../components/SupportPreview'
import { SUPPORT_PREVIEWS, SUPPORT_PREVIEWS_STRENGTHENED } from './constants'

interface GenerateSupportsProps {}

const GenerateSupports: FC<GenerateSupportsProps> = () => {
  const {
    params: { jobPathKey, outputPathKey }
  } = useRouteMatch<{ jobPathKey: string; outputPathKey: string }>()
  const { setStep } = stepStore
  const { setSupportConfigFiled, supportConfig } = configState

  useEffect(() => {
    setStep(JOB_STEP.DESIGN_SUPPORT)
  }, [setStep])

  const onChangeConfigInput = <K extends keyof TSupportConfig>(field: K) => {
    return (value: TSupportConfig[K]) => {
      setSupportConfigFiled(field, value)
    }
  }

  const createSelectOptionsFromEnum = (arrValues: string[]) =>
    arrValues.map((value) => ({
      optionLabel: value.charAt(0).toUpperCase() + value.slice(1),
      value
    }))

  useEffect(() => {
    if (partsStore.selectedJob && partsStore.list.length) {
      partsStore.setSelectedPart(partsStore.selectedJob.sourceFile.pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedJob, partsStore.list])

  useEffect(() => {
    const getJob = async () => {
      if (!partsStore.selectedJob && jobPathKey) {
        await partsStore.getJob(jobPathKey)
      }
    }
    getJob()
  }, [jobPathKey])

  useEffect(() => {
    const getJobOutput = async () => {
      await partsStore.getJobOutput(outputPathKey)
    }
    if (partsStore.selectedJob && !partsStore.selectedJobOutput && outputPathKey) {
      getJobOutput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputPathKey, partsStore.selectedJob, partsStore.selectedPart && partsStore.selectedJobOutput])

  useEffect(() => {
    const getFilesDataAndSetToViewer = async () => {
      let blobConfigData
      const arrUrls = partsStore.outputFiles.filter((file) => !!file).map((file) => file.url!)
      if (arrUrls[3]) {
        blobConfigData = await partsStore.getArrayBuffer(arrUrls[3])
      }
      viewerStore.setFilesUrls(arrUrls.slice(0, 3))
      blobConfigData && viewerStore.setDispConfigData(blobConfigData)
    }

    getFilesDataAndSetToViewer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.outputFiles])

  return (
    <div className='general-page-style design-supports'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Design Supports</div>
        <div className='general-page-style__content__left-block design-supports__content'>
          <div className='design-supports__content__inputs'>
            <SelectInput
              label={'Primary Support Geometry'}
              value={supportConfig.supp_geom}
              options={createSelectOptionsFromEnum(Object.values(DESIGN_SUPPORT))}
              onChange={onChangeConfigInput('supp_geom')}
            />
            <SelectInput
              label={'Strengthened Support Geometry'}
              value={supportConfig.str_supp_geom}
              options={createSelectOptionsFromEnum(Object.values(STRENG_DESIGN_SUPPORTS))}
              onChange={onChangeConfigInput('str_supp_geom')}
            />
            <SelectInput
              label={'Primary Attachment Geometry'}
              value={supportConfig.attach_geom}
              isContainsEmpty={true}
              options={createSelectOptionsFromEnum(Object.values(PRIMARY_ATTACHMENT_GEOMETRY))}
              onChange={onChangeConfigInput('attach_geom')}
            />
            <SelectInput
              label={'Strengthened Attachment Geometry'}
              value={supportConfig.str_attach_geom}
              isContainsEmpty={true}
              options={createSelectOptionsFromEnum(Object.values(STRENG_ATTACHMENT_GEOMETRY))}
              onChange={onChangeConfigInput('str_attach_geom')}
            />
          </div>
        </div>
      </div>
      <div className='design-supports__support-preview'>
        <SupportPreview
          name='Primary Supports'
          support={SUPPORT_PREVIEWS[supportConfig.supp_geom][supportConfig.attach_geom]}
        />
        <SupportPreview
          name='Strengthened Supports'
          support={SUPPORT_PREVIEWS_STRENGTHENED[supportConfig.str_supp_geom][supportConfig.str_attach_geom]}
        />
      </div>
    </div>
  )
}
export default observer(GenerateSupports)
