export interface User {
  id: string
  name: string
  avatar_url: string
}

export const mockedUser: User = {
  id: 'empty',
  name: 'A',
  avatar_url: 'https://i.pravatar.cc/150?img=1'
}
