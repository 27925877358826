import { makeAutoObservable } from 'mobx'

import { Preloader, defaultPreloaderState } from './types'

class PreloaderState {
  preloader: Preloader = defaultPreloaderState

  constructor() {
    makeAutoObservable(this)
  }

  showPreloader = () => {
    this.preloader.requestQty++
    if (!this.preloader.isShow) {
      this.preloader.isShow = true
    }
  }

  hidePreloader = () => {
    this.preloader.requestQty--

    if (!this.preloader.requestQty) {
      this.preloader.isShow = false
    }
  }
}

export default new PreloaderState()
