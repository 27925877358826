import partStore from '../../../store/parts'
import jobConfigStore from '../../../store/jobConfig'

import { Box3, DoubleSide, Group, Mesh, MeshPhongMaterial, Vector3 } from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

export class Supports {
  config = jobConfigStore.config
  selectedPart = partStore.selectedPart
  mesh = new Mesh()
  group = new Group()
  height = 0
  // constructor() {}

  add(url: string) {
    const material = new MeshPhongMaterial({ color: 0x8e44ad, side: DoubleSide })
    const loader = new STLLoader()

    return loader
      .loadAsync(url)
      .then((geometry) => {
        this.mesh = new Mesh(geometry, material)
        this.group.add(this.mesh)
        this.group.name = 'supp'
        this.mesh.geometry.computeVertexNormals()
        const bbox = new Box3().setFromObject(this.mesh)
        this.height = bbox.getSize(new Vector3()).z
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // setColor() {
  //   const color = new Color(0x8e44ad)
  //   const count = this.mesh.geometry.attributes.position.count
  //   const colorData = new Float32Array(count * 3)
  //   for (let i = 0; i < count * 3; i += 1) {
  //     colorData[i * 3] = color.r
  //     colorData[i * 3 + 1] = color.g
  //     colorData[i * 3 + 2] = color.b
  //   }
  //   this.mesh.geometry.setAttribute('color', new BufferAttribute(colorData, 3))
  //   console.log(this.mesh)
  // }
}
