import { FC } from 'react'

import './Table.scss'

export enum TABLE_COLUMN_ALIGN_TYPE {
  CENTER = 'center',
  START = 'start',
  END = 'end'
}
export interface ITableHeaderCell {
  value: string | number
  align?: TABLE_COLUMN_ALIGN_TYPE
  width?: number
}
export interface ITableCell {
  value: string | number
  isCompleted?: boolean
}

type TTableRaw = (ITableCell | null)[]

type TableProps = {
  headings: ITableHeaderCell[]
  rows: TTableRaw[]
}

const Table: FC<TableProps> = ({ headings, rows }) => {
  return (
    <table className='general-table'>
      <thead>
        <tr>
          {headings.map(({ width, align, value }, index) => (
            <th key={`table-header-${index}`} style={{ width }} className={`aling-${align}`}>
              {value}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, indexRow) => (
          <tr key={`table-row-${indexRow}`}>
            {row.map((cell, indexCell) => (
              <td
                key={`table-cell-${indexRow}-${indexCell}`}
                className={`${cell?.isCompleted && 'completed'} aling-${headings[indexCell].align}`}
              >
                {cell?.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
