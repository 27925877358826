import Reset from '../../Icons/Reset'

import './ResetButton.scss'

type ResetButtonProps = { onClick: () => void }

const ResetBtn = ({ onClick }: ResetButtonProps) => {
  return (
    <div onClick={onClick} className='reset-button'>
      <Reset />
      <div className='reset-button__text'>Reset</div>
    </div>
  )
}

export default ResetBtn
