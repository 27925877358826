import { FC, useEffect } from 'react'
import { ChangeEvent, useState } from 'react'

import './InputRange.scss'

interface InputRangeProps {
  min: number
  max: number
  step: number
  initValue: number
  id: string
  label: string
  onRangeChange: (value: number, id: string) => void
}

const InputRange: FC<InputRangeProps> = ({ min, max, step, initValue, id, label, onRangeChange }) => {
  const [rangeVal, setRangeVal] = useState(initValue)
  const [inputVal, setInputVal] = useState(initValue)

  useEffect(() => {
    if (initValue !== rangeVal) {
      setInputVal(initValue)
      setRangeVal(initValue)
    }
  }, [initValue, setRangeVal, setInputVal, rangeVal])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    setRangeVal(val)
    setInputVal(val)
    onRangeChange(val, id)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    !isNaN(val) && setInputVal(val)
  }
  const blur = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    if (val < min || val > max) {
      setInputVal(rangeVal)
    } else {
      setInputVal(val)
      onRangeChange(val, id)
    }
  }

  return (
    <div className='input-range general-input-style'>
      <label className='input-range__label'>{label}</label>
      <div className='input-range__input'>
        <input type='range' min={min} max={max} step={step} value={rangeVal} onChange={onChange} />
        <input className='input-range__input__field' value={inputVal} id={id} onChange={handleChange} onBlur={blur} />
      </div>
    </div>
  )
}

export default InputRange
