import { FC } from 'react'

import MainHeader from '../../components/MainHeader'
import MainNavBar from '../../components/MainNavBar'

import './MainLayout.scss'

type MainLayoutProps = {}

const MainLayout: FC<MainLayoutProps> = (props) => {
  return (
    <div className='main-layout'>
      <div className='main-layout__nav-bar'>
        <MainNavBar />
      </div>
      <div className='main-layout__page'>
        <div className='main-layout__page__header'>
          <MainHeader />
        </div>
        <div className='main-layout__page__content'>{props.children}</div>
      </div>
    </div>
  )
}

export default MainLayout
