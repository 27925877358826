import { FC, useEffect } from 'react'
import { generatePath, RouteComponentProps, useRouteMatch } from 'react-router'
import GeneralButton from '../../components/UI/Buttons/GeneralButton'
import { ROUTES } from '../../router/types'

import stepStore from '../../store/jobProgress'
import partsStore from '../../store/parts'
import preloaderStore from '../../store/preloader'
import viewerStore from '../../store/viewer'

import { JOB_STEP, ORIENTATION_TYPE } from '../../store/jobProgress/types'

import './OrientationType.scss'
import { observer } from 'mobx-react-lite'

interface OrientationTypeProps {
  history: RouteComponentProps['history']
}

const OrientationType: FC<OrientationTypeProps> = ({ history }) => {
  const { setStep } = stepStore
  const {
    params: { pathKey }
  } = useRouteMatch<{ pathKey: string }>()

  useEffect(() => {
    setStep(JOB_STEP.ORIENTATION_TYPE)
  }, [setStep])

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  const pathTo = (path: ROUTES, orientation: ORIENTATION_TYPE) =>
    generatePath(path, {
      pathKey,
      orientType: orientation
    })

  const onChoseManualOrientatio = () => {
    history.push(pathTo(ROUTES.MANUAL_ORIENTATION, ORIENTATION_TYPE.MANUAL))
  }
  const onChooseAMBuilderOptimization = () => {
    history.push(pathTo(ROUTES.OPTIMIZER_OPTIONS, ORIENTATION_TYPE.OPTIMIZER))
  }

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  useEffect(() => {
    const selectedPart = partsStore.selectedPart
    const getDownloadUrls = async () => {
      console.log('çheck', selectedPart, viewerStore.fileUrls.length)
      if (selectedPart && !viewerStore.fileUrls.length) {
        preloaderStore.showPreloader()
        const urls = await partsStore.getDownloadURL({
          filename: selectedPart.name,
          sourceFilePathKey: selectedPart.pathKey
        })
        viewerStore.setFilesUrls([urls])
        preloaderStore.hidePreloader()
      }
    }
    getDownloadUrls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedPart])

  return (
    <div className='orientation-type-page general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Orient Part</div>
        <div className='general-page-style__content__left-block orientation-type-page__content'>
          <div className='orientation-type-page__content__actions'>
            <GeneralButton onClick={onChoseManualOrientatio}>Orient Manually</GeneralButton>
            <GeneralButton onClick={onChooseAMBuilderOptimization}>Submit to AM Build Optimizer</GeneralButton>
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(OrientationType)
