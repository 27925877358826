import { FC } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import LoadableImage from '../UI/LoadableImage'

import authenticationState from '../../store/auth'

import routes from './routes'

import './MainNavBar.scss'

type MainNavBarProps = {}

const MainNavBar: FC<MainNavBarProps> = () => {
  const user = authenticationState.user

  return (
    <div className='main-navigation'>
      <div className='main-navigation__brand'>
        <div className='main-navigation__brand__logo'>{user?.name.charAt(0).toUpperCase()}</div>
      </div>
      <div className='main-navigation__routes'>
        {routes.map(({ Icon, path }, index) => (
          <div key={`main-nav-button-${index}`} className='main-navigation__routes__item'>
            <Link to={path}>
              <Icon />
            </Link>
          </div>
        ))}
      </div>
      <div className='main-navigation__user'>
        <div className='main-navigation__user__avatar'>
          <LoadableImage url={user?.avatar_url || ''} />
        </div>
      </div>
    </div>
  )
}

export default observer(MainNavBar)
