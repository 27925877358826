import { FC, useEffect } from 'react'

import stepStore from '../../store/jobProgress'
import { JOB_STEP } from '../../store/jobProgress/types'

import './ModifySupports.scss'

interface ModifySupportsProps {}

const ModifySupports: FC<ModifySupportsProps> = () => {
  const { setStep } = stepStore

  useEffect(() => {
    setStep(JOB_STEP.MODIFY_SUPPORTS)
  }, [setStep])

  return (
    <div className='modify-supports-page general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Modify Supports</div>
        <div className='general-page-style__content__left-block modify-supports-page__content'></div>
      </div>
    </div>
  )
}
export default ModifySupports
