import { Color, Mesh } from 'three'
import { Lut } from 'three/examples/jsm/math/Lut'

type DistConfig = {
  dispLegendZ: number[] | Float64Array
  dispLegendY: number[] | Float64Array
  dispLegendX: number[] | Float64Array
  dispLegendN: number[] | Float64Array
  dispLegendZ_on: number[] | Float64Array
  dispLegendY_on: number[] | Float64Array
  dispLegendX_on: number[] | Float64Array
  dispLegendN_on: number[] | Float64Array
  dispLegendZ_removal: number[] | Float64Array
  dispLegendY_removal: number[] | Float64Array
  dispLegendX_removal: number[] | Float64Array
  dispLegendN_removal: number[] | Float64Array
  dispZ: Color[] | Uint8Array
  dispY: Color[] | Uint8Array
  dispX: Color[] | Uint8Array
  dispN: Color[] | Uint8Array
  dispZ_on: Color[] | Uint8Array
  dispY_on: Color[] | Uint8Array
  dispX_on: Color[] | Uint8Array
  dispN_on: Color[] | Uint8Array
  dispZ_removal: Color[] | Uint8Array
  dispY_removal: Color[] | Uint8Array
  dispX_removal: Color[] | Uint8Array
  dispN_removal: Color[] | Uint8Array
  distZ: ArrayBuffer | []
  distY: ArrayBuffer | []
  distX: ArrayBuffer | []
  distN: ArrayBuffer | []
  distZ_on: ArrayBuffer | []
  distY_on: ArrayBuffer | []
  distX_on: ArrayBuffer | []
  distN_on: ArrayBuffer | []
  distZ_removal: ArrayBuffer | []
  distY_removal: ArrayBuffer | []
  distX_removal: ArrayBuffer | []
  distN_removal: ArrayBuffer | []
}

const distConfig: DistConfig = {
  dispLegendZ_on: [],
  dispZ_on: [],
  dispLegendY_on: [],
  dispY_on: [],
  dispLegendX_on: [],
  dispX_on: [],
  dispLegendN_on: [],
  dispN_on: [],
  dispLegendZ: [],
  dispZ: [],
  dispLegendY: [],
  dispY: [],
  dispLegendX: [],
  dispX: [],
  dispLegendN: [],
  dispN: [],
  dispLegendZ_removal: [],
  dispZ_removal: [],
  dispLegendY_removal: [],
  dispY_removal: [],
  dispLegendX_removal: [],
  dispX_removal: [],
  dispLegendN_removal: [],
  dispN_removal: [],
  distZ: [],
  distY: [],
  distX: [],
  distN: [],
  distZ_removal: [],
  distY_removal: [],
  distX_removal: [],
  distN_removal: [],
  distZ_on: [],
  distY_on: [],
  distX_on: [],
  distN_on: []
}

const calculateMinMaxFixNaN = (distData: any) => {
  let min = Number.MAX_VALUE
  let max = Number.MIN_VALUE
  for (let i = 0; i < distData.length; i++) {
    if (isNaN(distData[i])) {
      distData[i] = 0
    }
    if (distData[i] < min) {
      min = distData[i]
    }
    if (distData[i] > max) {
      max = distData[i]
    }
  }
  return [min, max]
}

const processColors = (distData: any, distMin: number, distMax: number) => {
  const colorData = []

  const lut = new Lut('rainbow', 512)
  lut.setMin(distMin)
  lut.setMax(distMax)
  for (let i = 0; i < distData.length; i++) {
    colorData[i] = lut.getColor(distData[i])
  }
  return colorData
}

export const parseBinFile = (arrayBuffer: ArrayBuffer, index: number, mesh: Mesh) => {
  const facesCount = [mesh.geometry.attributes.normal.count / 3]

  const fileLength = arrayBuffer.byteLength
  const fullColorLength = (16 + facesCount[index] * 3) * 12
  const onColorLength = (16 + facesCount[index] * 3) * 4
  const fullDistLength = facesCount[index] * 12 * 4
  const onDistLength = facesCount[index] * 4 * 4
  const colorDataLength = facesCount[index] * 3
  const distDataLength = facesCount[index] * 4
  const isDistortionData = fileLength === fullDistLength || fileLength === onDistLength

  if (fileLength === fullDistLength || fileLength === fullColorLength) {
    distConfig.dispLegendZ = []
    distConfig.dispZ = []
    distConfig.dispLegendY = []
    distConfig.dispY = []
    distConfig.dispLegendX = []
    distConfig.dispX = []
    distConfig.dispLegendN = []
    distConfig.dispN = []
    distConfig.dispLegendZ_removal = []
    distConfig.dispZ_removal = []
    distConfig.dispLegendY_removal = []
    distConfig.dispY_removal = []
    distConfig.dispLegendX_removal = []
    distConfig.dispX_removal = []
    distConfig.dispLegendN_removal = []
    distConfig.dispN_removal = []
  }
  console.log('isDistortionData', fileLength === fullColorLength)
  let view
  if (isDistortionData) {
    if (fileLength === onDistLength) {
      distConfig.distZ_on = new Float32Array(arrayBuffer, 0 * distDataLength, facesCount[index])
      distConfig.distY_on = new Float32Array(arrayBuffer, 1 * distDataLength, facesCount[index])
      distConfig.distX_on = new Float32Array(arrayBuffer, 2 * distDataLength, facesCount[index])
      distConfig.distN_on = new Float32Array(arrayBuffer, 3 * distDataLength, facesCount[index])
      distConfig.dispLegendZ_on = calculateMinMaxFixNaN(distConfig.distZ_on)
      distConfig.dispLegendY_on = calculateMinMaxFixNaN(distConfig.distY_on)
      distConfig.dispLegendX_on = calculateMinMaxFixNaN(distConfig.distX_on)
      distConfig.dispLegendN_on = calculateMinMaxFixNaN(distConfig.distN_on)
      distConfig.dispZ_on = processColors(
        distConfig.distZ_on,
        distConfig.dispLegendZ_on[0],
        distConfig.dispLegendZ_on[1]
      )
      distConfig.dispY_on = processColors(
        distConfig.distY_on,
        distConfig.dispLegendY_on[0],
        distConfig.dispLegendY_on[1]
      )
      distConfig.dispX_on = processColors(
        distConfig.distX_on,
        distConfig.dispLegendX_on[0],
        distConfig.dispLegendX_on[1]
      )
      distConfig.dispN_on = processColors(
        distConfig.distN_on,
        distConfig.dispLegendN_on[0],
        distConfig.dispLegendN_on[1]
      )
    } else if (fileLength === fullDistLength) {
      distConfig.distZ = new Float32Array(arrayBuffer, 0 * distDataLength, facesCount[index])
      distConfig.distY = new Float32Array(arrayBuffer, 1 * distDataLength, facesCount[index])
      distConfig.distX = new Float32Array(arrayBuffer, 2 * distDataLength, facesCount[index])
      distConfig.distN = new Float32Array(arrayBuffer, 3 * distDataLength, facesCount[index])
      distConfig.distZ_removal = new Float32Array(arrayBuffer, 4 * distDataLength, facesCount[index])
      distConfig.distY_removal = new Float32Array(arrayBuffer, 5 * distDataLength, facesCount[index])
      distConfig.distX_removal = new Float32Array(arrayBuffer, 6 * distDataLength, facesCount[index])
      distConfig.distN_removal = new Float32Array(arrayBuffer, 7 * distDataLength, facesCount[index])
      distConfig.distZ_on = new Float32Array(arrayBuffer, 8 * distDataLength, facesCount[index])
      distConfig.distY_on = new Float32Array(arrayBuffer, 9 * distDataLength, facesCount[index])
      distConfig.distX_on = new Float32Array(arrayBuffer, 10 * distDataLength, facesCount[index])
      distConfig.distN_on = new Float32Array(arrayBuffer, 11 * distDataLength, facesCount[index])
      distConfig.dispLegendZ = calculateMinMaxFixNaN(distConfig.distZ)
      distConfig.dispLegendY = calculateMinMaxFixNaN(distConfig.distY)
      distConfig.dispLegendX = calculateMinMaxFixNaN(distConfig.distX)
      distConfig.dispLegendN = calculateMinMaxFixNaN(distConfig.distN)
      distConfig.dispLegendZ_removal = calculateMinMaxFixNaN(distConfig.distZ_removal)
      distConfig.dispLegendY_removal = calculateMinMaxFixNaN(distConfig.distY_removal)
      distConfig.dispLegendX_removal = calculateMinMaxFixNaN(distConfig.distX_removal)
      distConfig.dispLegendN_removal = calculateMinMaxFixNaN(distConfig.distN_removal)
      distConfig.dispLegendZ_on = calculateMinMaxFixNaN(distConfig.distZ_on)
      distConfig.dispLegendY_on = calculateMinMaxFixNaN(distConfig.distY_on)
      distConfig.dispLegendX_on = calculateMinMaxFixNaN(distConfig.distX_on)
      distConfig.dispLegendN_on = calculateMinMaxFixNaN(distConfig.distN_on)
      distConfig.dispZ = processColors(distConfig.distZ, distConfig.dispLegendZ[0], distConfig.dispLegendZ[1])
      distConfig.dispY = processColors(distConfig.distY, distConfig.dispLegendY[0], distConfig.dispLegendY[1])
      distConfig.dispX = processColors(distConfig.distX, distConfig.dispLegendX[0], distConfig.dispLegendX[1])
      distConfig.dispN = processColors(distConfig.distN, distConfig.dispLegendN[0], distConfig.dispLegendN[1])
      distConfig.dispZ_removal = processColors(
        distConfig.distZ_removal,
        distConfig.dispLegendZ_removal[0],
        distConfig.dispLegendZ_removal[1]
      )
      distConfig.dispY_removal = processColors(
        distConfig.distY_removal,
        distConfig.dispLegendY_removal[0],
        distConfig.dispLegendY_removal[1]
      )
      distConfig.dispX_removal = processColors(
        distConfig.distX_removal,
        distConfig.dispLegendX_removal[0],
        distConfig.dispLegendX_removal[1]
      )
      distConfig.dispN_removal = processColors(
        distConfig.distN_removal,
        distConfig.dispLegendN_removal[0],
        distConfig.dispLegendN_removal[1]
      )
      distConfig.dispZ_on = processColors(
        distConfig.distZ_on,
        distConfig.dispLegendZ_on[0],
        distConfig.dispLegendZ_on[1]
      )
      distConfig.dispY_on = processColors(
        distConfig.distY_on,
        distConfig.dispLegendY_on[0],
        distConfig.dispLegendY_on[1]
      )
      distConfig.dispX_on = processColors(
        distConfig.distX_on,
        distConfig.dispLegendX_on[0],
        distConfig.dispLegendX_on[1]
      )
      distConfig.dispN_on = processColors(
        distConfig.distN_on,
        distConfig.dispLegendN_on[0],
        distConfig.dispLegendN_on[1]
      )
    }
  } else {
    if (fileLength === onColorLength) {
      view = new DataView(arrayBuffer, 16 * 0 + colorDataLength * 0, 16)
      distConfig.dispLegendZ_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 1 + colorDataLength * 1, 16)
      distConfig.dispLegendY_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 2 + colorDataLength * 2, 16)
      distConfig.dispLegendX_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 3 + colorDataLength * 3, 16)
      distConfig.dispLegendN_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      distConfig.dispZ_on = new Uint8Array(arrayBuffer, 16 * 1 + colorDataLength * 0, colorDataLength)
      distConfig.dispY_on = new Uint8Array(arrayBuffer, 16 * 2 + colorDataLength * 1, colorDataLength)
      distConfig.dispX_on = new Uint8Array(arrayBuffer, 16 * 3 + colorDataLength * 2, colorDataLength)
      distConfig.dispN_on = new Uint8Array(arrayBuffer, 16 * 4 + colorDataLength * 3, colorDataLength)
    } else if (fileLength === fullColorLength) {
      view = new DataView(arrayBuffer, 16 * 0 + colorDataLength * 0, 16)
      distConfig.dispLegendZ = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 1 + colorDataLength * 1, 16)
      distConfig.dispLegendY = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 2 + colorDataLength * 2, 16)
      distConfig.dispLegendX = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 3 + colorDataLength * 3, 16)
      distConfig.dispLegendN = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 4 + colorDataLength * 4, 16)
      distConfig.dispLegendZ_removal = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 5 + colorDataLength * 5, 16)
      distConfig.dispLegendY_removal = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 6 + colorDataLength * 6, 16)
      distConfig.dispLegendX_removal = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 7 + colorDataLength * 7, 16)
      distConfig.dispLegendN_removal = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 8 + colorDataLength * 8, 16)
      distConfig.dispLegendZ_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 9 + colorDataLength * 9, 16)
      distConfig.dispLegendY_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 10 + colorDataLength * 10, 16)
      distConfig.dispLegendX_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      view = new DataView(arrayBuffer, 16 * 11 + colorDataLength * 11, 16)
      distConfig.dispLegendN_on = new Float64Array([view.getFloat64(0, true), view.getFloat64(8, true)])
      distConfig.dispZ = new Uint8Array(arrayBuffer, 16 * 1 + colorDataLength * 0, colorDataLength)
      distConfig.dispY = new Uint8Array(arrayBuffer, 16 * 2 + colorDataLength * 1, colorDataLength)
      distConfig.dispX = new Uint8Array(arrayBuffer, 16 * 3 + colorDataLength * 2, colorDataLength)
      distConfig.dispN = new Uint8Array(arrayBuffer, 16 * 4 + colorDataLength * 3, colorDataLength)
      distConfig.dispZ_removal = new Uint8Array(arrayBuffer, 16 * 5 + colorDataLength * 4, colorDataLength)
      distConfig.dispY_removal = new Uint8Array(arrayBuffer, 16 * 6 + colorDataLength * 5, colorDataLength)
      distConfig.dispX_removal = new Uint8Array(arrayBuffer, 16 * 7 + colorDataLength * 6, colorDataLength)
      distConfig.dispN_removal = new Uint8Array(arrayBuffer, 16 * 8 + colorDataLength * 7, colorDataLength)
      distConfig.dispZ_on = new Uint8Array(arrayBuffer, 16 * 9 + colorDataLength * 8, colorDataLength)
      distConfig.dispY_on = new Uint8Array(arrayBuffer, 16 * 10 + colorDataLength * 9, colorDataLength)
      distConfig.dispX_on = new Uint8Array(arrayBuffer, 16 * 11 + colorDataLength * 10, colorDataLength)
      distConfig.dispN_on = new Uint8Array(arrayBuffer, 16 * 12 + colorDataLength * 11, colorDataLength)
    }
  }
  return distConfig
}
