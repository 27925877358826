import { FC, useEffect, useRef } from 'react'
import { XViewer } from '../../services/viewer'
import { HandViewerBtn, ResetViewerBtn, ZoomViewerBtn } from '../UI/Buttons/ViewerButtons'

import './Viewer.scss'
import { observer } from 'mobx-react-lite'

import viewerState from '../../store/viewer'
import jobProgress from '../../store/jobProgress'
import configStore from '../../store/jobConfig'
import { JOB_STEP } from '../../store/jobProgress/types'
import ViewerPreloader from '../ViewerPreloader'

const Viewer: FC = () => {
  const { currentTag, fileUrls, dispConfigData, isLoading, setLoading } = viewerState
  const { RotationalAngle, scaling, RestrictedFaces, AllowedFaces, OptFaces, printer } = configStore.config
  const currentPrinter =
    configStore.printerConfigs.find(({ name }) => name === printer) || configStore.printerConfigs[0]
  let viewerRef = useRef<XViewer | null>()

  const { current: viewer } = viewerRef

  const currentStep = jobProgress.activeStep
  const needTagsReset = !RestrictedFaces.length && !AllowedFaces.length && !OptFaces.length

  useEffect(() => {
    setLoading(true)
    const funcProps = { togglePanRotateBtn }
    if (fileUrls[0]) viewerRef.current = new XViewer(currentTag, fileUrls, currentPrinter, funcProps)

    return () => {
      viewerRef.current && viewerRef.current.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrls])

  useEffect(() => {
    if (!viewer || isLoading || !currentStep) return

    const needBuildPlate = ![JOB_STEP.TAG_SURFACE, JOB_STEP.OPTIMIZER_OPTIONS, JOB_STEP.ORIENTATION_TYPE].includes(
      currentStep
    )
    const needSupp = [JOB_STEP.MODIFY_SUPPORTS, JOB_STEP.DESIGN_SUPPORT].includes(currentStep)
    const suppOnScene = viewer.scene.getObjectByName('supp')

    if (fileUrls[1] && !isLoading && viewer && !suppOnScene) {
      setLoading(true)
      viewer.toggleSupport(needSupp)
    }
    if (!needSupp && suppOnScene) viewer.toggleSupport(false)

    viewer.toggleTagging(currentStep === JOB_STEP.TAG_SURFACE)
    viewer.toggleBuildChamber(needBuildPlate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isLoading, viewer])

  useEffect(() => {
    if (currentStep !== JOB_STEP.MANUAL_ORIENTATION || !viewer) return
    viewer.applyInputRotation(RotationalAngle)
    viewer.applyInputScaling(scaling)
  }, [RotationalAngle, scaling, currentStep, viewer])

  useEffect(() => {
    if (currentStep !== JOB_STEP.TAG_SURFACE || !viewer) return
    viewer.setCurrentTag(currentTag)
  }, [currentTag, currentStep, viewer])

  useEffect(() => {
    needTagsReset && viewer && viewer.resetTags()
  }, [needTagsReset, viewer])

  useEffect(() => {
    if (!dispConfigData || !viewer || isLoading) return

    viewer.processDist(dispConfigData)
  }, [viewer, dispConfigData, isLoading])

  const onZoomClick = (value: boolean) => viewer && viewer.toggleZoom(value)
  const onHandClick = (value: boolean) => viewer && viewer.togglePanRotate(value)
  const onResetClick = () => viewer && viewer.resetCamera()

  const togglePanRotateBtn = (val: boolean) => {
    const el = document.getElementById('viewer-btn-hand')
    if (!val) {
      el && el.classList.remove('selected')
    } else {
      el && el.classList.add('selected')
    }
  }

  return (
    <>
      <div className='viewer'>
        <div className='viewer-content'>
          <div id='x-viewer'></div>
          <div id='inset' className='viewer-content__inset'></div>
          <div className='viewer-content__icons'>
            <ZoomViewerBtn onClick={onZoomClick} />
            <HandViewerBtn onClick={onHandClick} />
            <ResetViewerBtn onClick={onResetClick} />
          </div>
        </div>
        <ViewerPreloader />
      </div>
    </>
  )
}

export default observer(Viewer)
