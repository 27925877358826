import { useState, useEffect } from 'react'

function useOnScreen(ref: React.MutableRefObject<HTMLElement | null>, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => ref.current && setIntersecting(entry.isIntersecting), {
      rootMargin
    })

    ref.current && observer.observe(ref.current)

    const refCopy = ref.current
    return () => {
      observer.unobserve(refCopy!)
    }
  }, [ref, rootMargin])

  return isIntersecting
}

export default useOnScreen
