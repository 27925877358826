import { makeAutoObservable } from 'mobx'
import { TAG } from './types'
import { tags } from './types'

class ViewerState {
  tags: TAG[] = tags
  currentTag: TAG = tags[0]
  fileUrls: string[] = []
  dispConfigData: any = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setFilesUrls = (urls: string[]) => {
    this.fileUrls = urls
  }
  setDispConfigData = (data: any) => {
    this.dispConfigData = data
  }
  setCurrentTag = (tag: TAG) => {
    this.currentTag = tag
  }
  setLoading = (value: boolean) => {
    this.isLoading = value
  }
}

export default new ViewerState()
