export enum ROUTES {
  SIGN_IN = '/signin',
  MAIN = '/',

  PARTS_LIST = '/parts',
  TAG_SURFACE = '/:pathKey/surface',
  ORIENTATION_TYPE = '/:pathKey/orient-type',
  OPTIMIZER_OPTIONS = '/:pathKey/optimizer',
  MANUAL_ORIENTATION = '/:pathKey/manual',

  GENERATE_SUPPORTS = '/:jobPathKey/:outputPathKey/generate-supp',
  DESIGN_SUPPORT = '/:jobPathKey/:outputPathKey/design-supp',
  MODIFY_SUPPORTS = '/:jobPathKey/:outputPathKey/modify-sup'
}
export interface IRouteConfig {
  key?: React.Key | undefined
  location?: Location | undefined
  component: React.FC<any>
  path: string | string[] | undefined
  exact?: boolean | undefined
  strict?: boolean | undefined
  routes?: IRouteConfig[] | undefined
  render?: ((props: any) => React.ReactNode) | undefined
}
