import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const routerStore = new RouterStore()

syncHistoryWithStore(history, routerStore)

export default routerStore
