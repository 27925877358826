import { FC } from 'react'
import { TSourceFile } from '../../types/files'
import { SHORTCUT_OVERALL_STEP } from '../../types/overallSteps'
import SpinnerIcon from '../UI/Icons/Spinner'
import TrashIcon from '../UI/Icons/Trash'
import LoadableImage from '../UI/LoadableImage'

import './PartIListtem.scss'

type PartIListtemProps = {
  part: TSourceFile
  isSelected: boolean
  deleteFile: () => void
  isDeleting: boolean
}

const mokedImage = 'https://s3-eu-west-1.amazonaws.com/3dhubs-knowledgebase/geometry-restrictions/photo1.jpg'

const PartIListtem: FC<PartIListtemProps> = ({ part, isSelected, deleteFile, isDeleting }) => {
  const onDeleteFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    deleteFile()
  }
  return (
    <div className={`part-list-item ${isSelected && 'selected'}`}>
      <div className='part-list-item__preview'>
        <LoadableImage url={mokedImage} containerWidth={75} />
      </div>
      <div className='part-list-item__data'>
        <div className='part-list-item__data__name'>{part.name}</div>
        <div className='part-list-item__data__next-step'>Next Step: {SHORTCUT_OVERALL_STEP.BUILD_PREPARATION}</div>
        <div className='part-list-item__data__actions'>
          <button onClick={onDeleteFile}>
            <div className='delete-button__icon-wrapper'>{isDeleting ? <SpinnerIcon /> : <TrashIcon />}</div>
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default PartIListtem
