export enum TAG_CONFIG_FIELD {
  UPSKIN = 'OptFaces',
  ALLOWED = 'AllowedFaces',
  RESTRICTED = 'RestrictedFaces',
  ERASE = ''
}
export enum TAG_SURFACE_COLOR {
  UPSKIN = '#FFB70B',
  ALLOWED = '#0BFF0B',
  RESTRICTED = '#FF0606',
  ERASE = '#666666'
}

export type TAG = { color: TAG_SURFACE_COLOR; tag: TAG_CONFIG_FIELD; value: string }
export const tags = [
  { tag: TAG_CONFIG_FIELD.RESTRICTED, color: TAG_SURFACE_COLOR.RESTRICTED, value: 'Restricted' },
  { tag: TAG_CONFIG_FIELD.UPSKIN, color: TAG_SURFACE_COLOR.UPSKIN, value: 'Upskin' },
  { tag: TAG_CONFIG_FIELD.ALLOWED, color: TAG_SURFACE_COLOR.ALLOWED, value: 'Machined/Prefered' },
  { tag: TAG_CONFIG_FIELD.ERASE, color: TAG_SURFACE_COLOR.ERASE, value: 'Erase' }
]
