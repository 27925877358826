import { makeAutoObservable } from 'mobx'
import { overallSteps, TOverallStep } from './types'

class OverallSteps {
  list: TOverallStep[] = overallSteps
  overallProgress: number = 30
  currentStep: number = 4

  constructor() {
    makeAutoObservable(this)
  }

  setOverallProgress = (num: number) => {
    this.overallProgress = num
  }
}

export default new OverallSteps()
