import { FC } from 'react'

import { partners } from './constants'

import './OpenWith.scss'

type OpenWithProps = {
  isDisabled: boolean
  onItemClick: () => void
}

const OpenWith: FC<OpenWithProps> = ({ isDisabled, onItemClick }) => {
  return (
    <div className='open-with'>
      <div className='open-with__title'>
        <div className='open-with__title__name'>Open with</div>
      </div>
      <div className='open-with__options'>
        {partners.map((partner) => (
          <div
            key={`partner-${partner.name}`}
            className={`open-with__options__item ${isDisabled && 'disabled'}`}
            onClick={onItemClick}
          >
            {partner.name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OpenWith
