import { FC, useEffect, useMemo } from 'react'

import InputsBlock from '../../components/UI/InputsBlock'
import CheckboxInput from '../../components/UI/Inputs/Checkbox'
import InputRange from '../../components/UI/Inputs/Range'
import SelectInput from '../../components/UI/Inputs/Select'
import NumberInput from '../../components/UI/Inputs/Number'
import GeneralButton from '../../components/UI/Buttons/GeneralButton'

import jobState from '../../store/jobProgress'
import preloaderStore from '../../store/preloader'
import viewerStore from '../../store/viewer'
import partsStore from '../../store/parts'
import configState from '../../store/jobConfig'
import { JOB_STEP, ORIENTATION_TYPE } from '../../store/jobProgress/types'

import './OptimizerOptions.scss'
import { observer } from 'mobx-react-lite'
import { generatePath, RouteComponentProps, useRouteMatch } from 'react-router'
import { TCustomWeights, TJobConfig, TObjectiveFunctions } from '../../store/jobConfig/types'
import { JOB_OUTPUT_STATUS } from '../../store/parts/types'

interface OptimizerOptionsProps {
  history: RouteComponentProps['history']
}

const OptimizerOptions: FC<OptimizerOptionsProps> = ({ history }) => {
  const { submitJob, createJob, getJob } = partsStore
  const { setStep, setOrientationType, nextStep } = jobState
  const {
    setConfigField,
    setCustomWeights,
    customWeights,
    computedObjectiveFunctions,
    setObjectiveFuncField,
    config,
    objectiveFunctions,
    printerConfigs,
    materialConfigs
  } = configState
  const {
    params: { pathKey }
  } = useRouteMatch<{ pathKey: string }>()

  useEffect(() => {
    setStep(JOB_STEP.OPTIMIZER_OPTIONS)
    setOrientationType(ORIENTATION_TYPE.OPTIMIZER)
  }, [setStep, setOrientationType])

  const onChangeConfigInput = <K extends keyof TJobConfig>(field: K) => {
    return (value: TJobConfig[K]) => {
      setConfigField(field, value)
    }
  }

  const onChangeCustomWeights = <K extends keyof TCustomWeights>(field: K) => {
    return (value: TCustomWeights[K]) => {
      setCustomWeights(field, value)
    }
  }

  const onChangeObjectiveFuncInput = <K extends keyof TObjectiveFunctions>(field: K) => {
    return (value: TObjectiveFunctions[K]) => {
      setObjectiveFuncField(field, value)
    }
  }

  const moveBack = () => history.goBack()
  const createAndRunJob = async () => {
    preloaderStore.showPreloader()
    const jobPathKey = await createJob({ config, sourceFilePathKey: pathKey })
    if (jobPathKey) {
      await submitJob({ jobPathKey, ...computedObjectiveFunctions })

      const checkIsJobFinished = setInterval(async () => {
        const responseJob = await getJob(jobPathKey)
        // TODO: what job i should to use? Job has a lot of outputs
        const jobOutput = responseJob?.jobOutputs.items.find(
          (output) =>
            output.analysisId === computedObjectiveFunctions.analysisId &&
            output.objectiveId === computedObjectiveFunctions.objectiveId
        )

        if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.COMPLETE) {
          history.push(
            generatePath(nextStep!.path, {
              jobPathKey,
              outputPathKey: jobOutput.pathKey
            })
          )
          clearInterval(checkIsJobFinished)
          preloaderStore.hidePreloader()
        }
        if (jobOutput && jobOutput.status === JOB_OUTPUT_STATUS.ERROR) {
          console.error('Unexpected error !')
          clearInterval(checkIsJobFinished)
          preloaderStore.hidePreloader()
        }
      }, 4000)
    }
  }

  const isClearObjectiveFunc = useMemo(
    () => Object.values(objectiveFunctions).every((isChecked) => !isChecked),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectiveFunctions.cust_weig, objectiveFunctions.min_dist, objectiveFunctions.optMaxDist]
  )

  const inputProps = {
    min: 0,
    max: 1,
    step: 0.1
  }

  useEffect(() => {
    if (!partsStore.selectedPart && pathKey && partsStore.list.length) {
      partsStore.setSelectedPart(pathKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, partsStore.list, partsStore.selectedPart])

  useEffect(() => {
    const selectedPart = partsStore.selectedPart
    const getDownloadUrls = async () => {
      if (selectedPart && !viewerStore.fileUrls.length) {
        preloaderStore.showPreloader()
        const urls = await partsStore.getDownloadURL({
          filename: selectedPart.name,
          sourceFilePathKey: selectedPart.pathKey
        })
        viewerStore.setFilesUrls([urls])
        preloaderStore.hidePreloader()
      }
    }
    getDownloadUrls()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsStore.selectedPart])

  return (
    <div className='optimizer-options general-page-style'>
      <div className='general-page-style__content'>
        <div className='general-page-style__content__page-name'>Orient Part</div>
        <div className='general-page-style__content__left-block optimizer-options__content'>
          <div className='optimizer-options__content__title'>AM Build Optimizer</div>
          <div className='optimizer-options__content__inputs'>
            <InputsBlock name='Job Detail'>
              <div className='row'>
                <SelectInput
                  label={'Material'}
                  value={config.material_name}
                  options={materialConfigs.map((material) => ({
                    optionLabel: material.name,
                    value: material.name
                  }))}
                  onChange={onChangeConfigInput('material_name')}
                />
                <SelectInput
                  label={'Printer'}
                  value={config.printer}
                  options={printerConfigs.map((printer) => ({
                    optionLabel: printer.name,
                    value: printer.name
                  }))}
                  onChange={onChangeConfigInput('printer')}
                />
              </div>
              <div className='row'>
                <NumberInput
                  label={'No. of lazers'}
                  value={config.NLaser[0]}
                  onChange={onChangeConfigInput('NLaser')}
                />
                <NumberInput
                  label={'Element size (mm)'}
                  value={config.elementsize[0]}
                  onChange={onChangeConfigInput('elementsize')}
                />
              </div>
              <NumberInput
                label={'Layer thickness (um)'}
                value={config.layerThickness[0]}
                onChange={onChangeConfigInput('layerThickness')}
              />
              <NumberInput
                label={'Critical self supporting angle (deg)'}
                value={config.critical_angle[0]}
                onChange={onChangeConfigInput('critical_angle')}
              />
            </InputsBlock>

            <InputsBlock name='Build Plate Parameters'>
              <NumberInput
                label={'Biuld plate temperature (C)'}
                value={config.Tplate[0]}
                onChange={onChangeConfigInput('Tplate')}
              />
              <NumberInput
                label={'Biuld plate thickness (mm)'}
                value={config.H_buildPlate[0]}
                onChange={onChangeConfigInput('H_buildPlate')}
              />
            </InputsBlock>

            <InputsBlock name='Objective Function'>
              <CheckboxInput
                className={!objectiveFunctions.min_dist && !isClearObjectiveFunc ? 'disabled' : ''}
                label={'Minimize distortion'}
                value={objectiveFunctions.min_dist}
                onChange={onChangeObjectiveFuncInput('min_dist')}
              />
              {objectiveFunctions.min_dist && (
                <>
                  <CheckboxInput
                    label={'Perform "stress relief" before removing parts from the build plate'}
                    value={config.isHeatTreatment}
                    onChange={onChangeConfigInput('isHeatTreatment')}
                  />
                  <CheckboxInput
                    label={'Minimal recoater interference is more important than overall dimensional accurary'}
                    value={config.isMinPeeling}
                    onChange={onChangeConfigInput('isMinPeeling')}
                  />
                </>
              )}
              <CheckboxInput
                className={!objectiveFunctions.optMaxDist && !isClearObjectiveFunc ? 'disabled' : ''}
                label={'Are you using stress relief? (Yes/No)'}
                value={objectiveFunctions.optMaxDist}
                onChange={onChangeObjectiveFuncInput('optMaxDist')}
              />
              {objectiveFunctions.optMaxDist && (
                <>
                  <NumberInput
                    label={'What is the maximum thermal distortion allowable on this part? +/- 1 millimeters'}
                    value={config.MaxDist[0]}
                    onChange={onChangeConfigInput('MaxDist')}
                  />
                </>
              )}
              <CheckboxInput
                className={!objectiveFunctions.cust_weig && !isClearObjectiveFunc ? 'disabled' : ''}
                label={'Custom Weighting? (Yes/No)'}
                value={objectiveFunctions.cust_weig}
                onChange={onChangeObjectiveFuncInput('cust_weig')}
              />
              {objectiveFunctions.cust_weig && (
                <>
                  <InputRange
                    id={'min_time'}
                    label={'Minimize post processing'}
                    initValue={customWeights.min_time}
                    onRangeChange={onChangeCustomWeights('min_time')}
                    {...inputProps}
                  />
                  <InputRange
                    id={'min_material'}
                    label={'Minimize material consumption'}
                    initValue={customWeights.min_material}
                    onRangeChange={onChangeCustomWeights('min_material')}
                    {...inputProps}
                  />
                  <InputRange
                    id={'min_build_time'}
                    label={'Minimize buildtime'}
                    initValue={customWeights.min_build_time}
                    onRangeChange={onChangeCustomWeights('min_build_time')}
                    {...inputProps}
                  />
                  <InputRange
                    id={'min_dist_range'}
                    label={'Minimize distortion'}
                    initValue={customWeights.min_dist_range}
                    onRangeChange={onChangeCustomWeights('min_dist_range')}
                    {...inputProps}
                  />
                </>
              )}
            </InputsBlock>
          </div>
          <div className='optimizer-options__content__actions'>
            <GeneralButton onClick={createAndRunJob}>Run job</GeneralButton>
            <GeneralButton onClick={moveBack}>Back</GeneralButton>
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(OptimizerOptions)
