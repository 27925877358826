import { ArrowHelper, Group, Mesh, MeshBasicMaterial, Object3D, Vector3 } from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'

export class Helper {
  axisGroup: Group = new Group()
  colors: { x: number; y: number; z: number } = { x: 0xff0000, y: 0x00ff00, z: 0x0000ff }

  constructor() {
    Object3D.DefaultUp.set(0, 0, 1)
    Object3D.DefaultMatrixAutoUpdate = true
    this.init()
  }

  init(length = 50) {
    const getArrow = (axis: Vector3, color: number) => new ArrowHelper(axis, new Vector3(0, 0, 0), length, color, 5, 5)
    const getTextMesh = (geometry: TextGeometry, color: number) => new Mesh(geometry, new MeshBasicMaterial({ color }))
    const loader = new FontLoader()

    const arrowX = getArrow(new Vector3(1, 0, 0), this.colors.x)
    const arrowY = getArrow(new Vector3(0, 1, 0), this.colors.y)
    const arrowZ = getArrow(new Vector3(0, 0, 1), this.colors.z)

    this.axisGroup.add(arrowX, arrowY, arrowZ)

    loader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
      const gX = new TextGeometry('X', { font, height: 0.5, size: 7 })
      const gY = new TextGeometry('Y', { font, height: 0.5, size: 7 })
      const gZ = new TextGeometry('Z', { font, height: 0.5, size: 7 })
      const meshX = getTextMesh(gX, this.colors.x)
      const meshY = getTextMesh(gY, this.colors.y)
      const meshZ = getTextMesh(gZ, this.colors.z)
      const r = (90 * Math.PI) / 180
      meshX.rotation.set(r, r, 0)
      meshX.position.set(length - 4, -3, 5)
      meshY.rotation.set(r, 0, 0)
      meshY.position.set(-3, length - 4, 5)
      meshZ.rotation.set(r, 0, 0)
      meshZ.position.set(5, 0, length - 8)
      this.axisGroup.add(meshX, meshY, meshZ)
    })
  }
}
