import { FC } from 'react'
import { observer } from 'mobx-react-lite'

import JobProgressStepsState from '../../store/jobProgress'
import partsState from '../../store/parts'
import routerState from '../../store/router'

import './ProgressBar.scss'
import { generatePath } from 'react-router'
import { ROUTES } from '../../router/types'

type ProgressBarProps = {}

const ProgressBar: FC<ProgressBarProps> = () => {
  const { generalSteps, activeStep, currentStep } = JobProgressStepsState

  const getRouteParams = (path: ROUTES) => {
    const selectedJob = partsState.selectedJob!
    const selectedPart = partsState.selectedPart!
    switch (path) {
      case ROUTES.TAG_SURFACE: {
        return {
          pathKey: selectedPart.pathKey
        }
      }
      case ROUTES.ORIENTATION_TYPE: {
        return {
          pathKey: selectedPart.pathKey
        }
      }
      case ROUTES.MANUAL_ORIENTATION: {
        return {
          pathKey: selectedPart.pathKey
        }
      }
      case ROUTES.OPTIMIZER_OPTIONS: {
        return {
          pathKey: selectedPart.pathKey
        }
      }
      case ROUTES.DESIGN_SUPPORT: {
        return {
          jobPathKey: selectedJob.pathKey
        }
      }
      case ROUTES.GENERATE_SUPPORTS: {
        return {
          jobPathKey: selectedJob.pathKey
        }
      }
      case ROUTES.MODIFY_SUPPORTS: {
        return {
          jobPathKey: selectedJob.pathKey
        }
      }
    }
    return null
  }

  const moveTo = (path: ROUTES) => {
    const routeParams = getRouteParams(path)
    if (routeParams) {
      routerState.push(generatePath(path, routeParams))
    }
  }

  return (
    <div className='progress-bar'>
      {Object.entries(generalSteps).map(([step, { path, name, isCompleted }]) => {
        return (
          <div
            key={`progress-step-${step}`}
            onClick={() => moveTo(path)}
            className={`progress-bar__item ${
              (Number(step) === activeStep || Number(step) === currentStep?.relatedToStep) && 'active'
            } ${isCompleted && 'completed'}`}
          >
            <div className='progress-bar__item__name'>{name}</div>
            <div className='progress-bar__item__mark'>
              <div className='progress-bar__item__mark__circle'></div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default observer(ProgressBar)
