import { makeAutoObservable } from 'mobx'

import { mockedUser, User } from './types'

class Authentication {
  user: User | null = mockedUser

  constructor() {
    makeAutoObservable(this)
  }

  setAuth(user: User) {
    this.user = user
  }

  logOut() {
    this.user = null
  }
}

export default new Authentication()
