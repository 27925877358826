import { makeAutoObservable } from 'mobx'

import { jobProgressSteps, TJobProgressStep, JOB_STEP, ORIENTATION_TYPE, TJobStep } from './types'

class JobProgressSteps {
  stepsList: TJobProgressStep = jobProgressSteps
  activeStep: JOB_STEP | null = null
  orientType: ORIENTATION_TYPE = ORIENTATION_TYPE.MANUAL

  constructor() {
    makeAutoObservable(this)
  }

  setOrientationType = (type: ORIENTATION_TYPE) => {
    this.orientType = type
  }

  setDefaultState = () => {
    this.stepsList = jobProgressSteps
    this.activeStep = null
    this.orientType = ORIENTATION_TYPE.MANUAL
  }

  // Actions for job progress steps

  setStep = (step: JOB_STEP) => {
    if (step) {
      const allSteps = Object.values(JOB_STEP).filter((x) => typeof x === 'number') as JOB_STEP[]

      for (let jobStep of allSteps) {
        if (jobStep === step) break
        this.stepsList[jobStep].isCompleted = true
      }
    }
    this.activeStep = step
  }

  get currentStep() {
    return this.activeStep ? this.stepsList[this.activeStep] : null
  }

  get nextStep() {
    let nextStep: TJobStep | null = null
    for (const [step, stepData] of Object.entries(this.stepsList)) {
      if (
        !nextStep &&
        // step bigger than active step
        this.activeStep &&
        Number(step) > this.activeStep &&
        // step is allow for selected orient part type
        (!stepData.orientType || stepData.orientType === this.orientType)
      ) {
        nextStep = stepData
      }
    }
    return nextStep
  }

  get generalSteps() {
    const cacheSteps: TJobProgressStep = Object.assign({}, this.stepsList)
    for (let [key, { relatedToStep }] of Object.entries(this.stepsList)) {
      if (relatedToStep) delete cacheSteps[Number(key) as JOB_STEP]
    }
    return cacheSteps
  }
}

export default new JobProgressSteps()
