export type TOverallStep = {
  title: string
  description?: string
  isDisabled: boolean
  isActive: boolean
  isCompleted: boolean
  markNum?: number
}

export const overallSteps: TOverallStep[] = [
  {
    title: 'Order & Delivery Management',
    description: 'Additive Manufacturing Network',
    isCompleted: true,
    isDisabled: false,
    isActive: false
  },
  {
    title: 'Design for AM',
    markNum: 1,
    isCompleted: true,
    isDisabled: false,
    isActive: false
  },
  {
    title: 'AM Design Validation',
    markNum: 2,
    isCompleted: true,
    isDisabled: false,
    isActive: false
  },
  {
    title: 'Generative Engeering',
    markNum: 3,
    isCompleted: true,
    isDisabled: false,
    isActive: false
  },
  {
    title: 'Build Preparation',
    markNum: 4,
    isCompleted: true,
    isDisabled: false,
    isActive: true
  },
  {
    title: 'Build Simulation',
    description: 'All build optimizer',
    markNum: 5,
    isCompleted: false,
    isDisabled: false,
    isActive: false
  },
  {
    title: 'Schedule & Execute',
    isCompleted: false,
    isDisabled: true,
    isActive: false
  },
  {
    title: 'Machine Connectivity',
    isCompleted: false,
    isDisabled: true,
    isActive: false
  },
  {
    title: 'Post processing',
    isCompleted: false,
    isDisabled: true,
    isActive: false
  },
  {
    title: 'Quality & process Monitoring',
    isCompleted: false,
    isDisabled: true,
    isActive: false
  }
]
