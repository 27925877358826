import SettingsIcon from '../UI/Icons/Settings'

import { ROUTES } from '../../router/types'
import React from 'react'

type NavigationItem = {
  Icon: React.FC
  path: ROUTES
}

const routes: NavigationItem[] = [
  {
    Icon: SettingsIcon,
    path: ROUTES.PARTS_LIST
  },
  {
    Icon: SettingsIcon,
    path: ROUTES.TAG_SURFACE
  }
]

export default routes
