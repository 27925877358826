import { FC } from 'react'

import './MainHeader.scss'

type MainHeaderProps = {}

const MainHeader: FC<MainHeaderProps> = () => {
  return <div className='main-header__logo'>Xcelerator</div>
}

export default MainHeader
