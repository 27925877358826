import axios, { AxiosResponse } from 'axios'
import { makeAutoObservable } from 'mobx'

import localStorageService from '../../services/localStorageService'
import { TAG_CONFIG_FIELD } from '../viewer/types'

import {
  defaultConfig,
  defaultCustomWeights,
  defaultObjectiveFunctions,
  defaultSupportConfig,
  TCustomWeights,
  TJobConfig,
  TMaterialConfig,
  TObjectiveFunctions,
  TPrinterConfig,
  TRoesponseGetConfig,
  TSupportConfig
} from './types'

class ConfigState {
  config = defaultConfig
  supportConfig = defaultSupportConfig
  objectiveFunctions = defaultObjectiveFunctions
  customWeights = defaultCustomWeights
  printerConfigs: TPrinterConfig[] = []
  materialConfigs: TMaterialConfig[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setJobConfig = (config: TJobConfig) => {
    this.config = config
  }

  getPrintConfig = async () => {
    try {
      const responsePrintConfig: AxiosResponse<TRoesponseGetConfig> = await axios.post(
        `/files/printconfig`,
        {
          targetVersion: '1.2.0'
        },
        {
          headers: {
            Authorization: `Bearer ${localStorageService.token}`
          }
        }
      )
      const { printers, materials } = responsePrintConfig.data.config

      this.printerConfigs = printers
      this.materialConfigs = materials
    } catch (error) {
      console.error(error)
    }
  }

  setConfigField = <K extends keyof TJobConfig>(field: K, value: TJobConfig[K]) => {
    this.config[field] = value
  }

  setSupportConfigFiled = <K extends keyof TSupportConfig>(field: K, value: TSupportConfig[K]) => {
    this.supportConfig[field] = value
  }

  setObjectiveFuncField = <K extends keyof TObjectiveFunctions>(field: K, value: TObjectiveFunctions[K]) => {
    this.setDefaultObjectiveFunctions()
    for (let key of Object.keys(this.objectiveFunctions)) {
      if (key !== field) {
        this.objectiveFunctions[key as K] = false
      }
    }
    this.objectiveFunctions[field] = value
  }
  setCustomWeights = <K extends keyof TCustomWeights>(field: K, value: TCustomWeights[K]) => {
    this.customWeights[field] = value
  }
  setFaces = (tag: TAG_CONFIG_FIELD, value: number[]) => {
    if (tag === TAG_CONFIG_FIELD.ERASE) return
    this.config[tag] = value
  }
  resetIFaces = () => {
    this.config[TAG_CONFIG_FIELD.RESTRICTED] = []
    this.config[TAG_CONFIG_FIELD.ALLOWED] = []
    this.config[TAG_CONFIG_FIELD.UPSKIN] = []
  }

  setDefaultObjectiveFunctions = () => {
    this.config.isHeatTreatment = defaultConfig.isHeatTreatment
    this.config.isMinPeeling = defaultConfig.isMinPeeling
    this.config.MaxDist = defaultConfig.MaxDist
  }

  get computedObjectiveFunctions() {
    let analysisId, objectiveId

    if (this.objectiveFunctions.min_dist) {
      analysisId = 5
      objectiveId = 1
      this.config.MaxDist = [-1]
    } else if (this.objectiveFunctions.optMaxDist) {
      analysisId = 5
      objectiveId = 14
    } else {
      analysisId = 4
      objectiveId = 21
      this.config.MaxDist = [-1]
    }
    return {
      analysisId,
      objectiveId
    }
  }
}

export default new ConfigState()
