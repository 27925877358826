import { TAG_SURFACE_COLOR } from './../../../store/viewer/types'
import { Group, MeshPhongMaterial, Mesh, Object3D, MathUtils, Box3, Color, BufferAttribute, Face, Vector3 } from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { TAG, TAG_CONFIG_FIELD } from '../../../store/viewer/types'
import { TJobConfig } from '../../../store/jobConfig/types'
import jobStore from '../../../store/jobConfig'

const deg2rad = MathUtils.degToRad

type FaceIntrs = { i: number; face: Face | null; color: Color }
type SelectedFaces = {
  [TAG_CONFIG_FIELD.RESTRICTED]: FaceIntrs[]
  [TAG_CONFIG_FIELD.ALLOWED]: FaceIntrs[]
  [TAG_CONFIG_FIELD.UPSKIN]: FaceIntrs[]
}
const initSelectedFace: SelectedFaces = {
  [TAG_CONFIG_FIELD.RESTRICTED]: [],
  [TAG_CONFIG_FIELD.ALLOWED]: [],
  [TAG_CONFIG_FIELD.UPSKIN]: []
}

export class Part {
  name: string
  size: [number, number, number] = [20, -30, 10]
  color: number
  group: Group = new Group()
  oldRotation: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 }
  isTagMode = false
  isRotationMode = false
  hsub: number
  mesh = new Mesh()
  prevFace: FaceIntrs
  selectedFaces = initSelectedFace
  distConfig: Color[] = []

  constructor(name = 'part', hsub = 5, color = 0x666666) {
    Object3D.DefaultUp.set(0, 0, 1)
    Object3D.DefaultMatrixAutoUpdate = true
    this.hsub = hsub
    this.name = name
    this.color = color
    this.prevFace = { i: -1, face: null, color: new Color(this.color) }
  }

  add(url: string) {
    const loader = new STLLoader()
    return loader
      .loadAsync(url)
      .then((geometry) => {
        const material = new MeshPhongMaterial({
          specular: 0x111111,
          shininess: 50,
          flatShading: true,
          vertexColors: true
        })
        this.mesh = new Mesh(geometry, material)
        this.group.add(this.mesh)
        this.setColor()
        this.group.name = this.name
      })
      .then(() => {
        this.setSize()
      })
  }

  //color
  setColor() {
    const color = new Color(this.color)
    const count = this.mesh.geometry.attributes.position.count
    const colorData = new Float32Array(count * 3)
    for (let i = 0; i < count * 3; i += 1) {
      colorData[i * 3] = color.r
      colorData[i * 3 + 1] = color.g
      colorData[i * 3 + 2] = color.b
    }
    this.mesh.geometry.setAttribute('color', new BufferAttribute(colorData, 3))
  }
  getFaceColor(face: Face | null) {
    if (!face) return new Color(this.color)

    const colorAttribute = this.mesh.geometry.getAttribute('color')
    const { a } = face
    const getXYZ = (i: number) =>
      ({ r: colorAttribute.getX(i), g: colorAttribute.getY(i), b: colorAttribute.getZ(i) } as Color)
    const xColor = getXYZ(a)
    return xColor
  }
  setFaceColor(face: Face | null, color: Color) {
    if (!face) return
    const { r, g, b } = color
    const colorAttribute = this.mesh.geometry.getAttribute('color')
    colorAttribute.setXYZ(face.a, r, g, b)
    colorAttribute.setXYZ(face.b, r, g, b)
    colorAttribute.setXYZ(face.c, r, g, b)
    colorAttribute.needsUpdate = true
  }
  setDistColor(colorConfig = this.distConfig) {
    this.distConfig = colorConfig
    const colorAttribute = this.mesh.geometry.getAttribute('color')
    for (let i = 0; i < this.mesh.geometry.attributes.position.count / 3; i++) {
      if (colorConfig[0] && colorConfig[0].hasOwnProperty('r')) {
        const inx = i * 9
        const { r, g, b } = colorConfig[i]
        new Array(9).fill(1).forEach((num, magickIndex) => {
          colorAttribute.setXYZ(inx + magickIndex, r, g, b)
        })
      }
    }
    this.mesh.geometry.attributes.color.needsUpdate = true
  }

  //faces
  colorFaceOnMove(faceIndex: number, face: Face, color: TAG_SURFACE_COLOR) {
    const currentColor = new Color(color)
    if (!this.prevFace.face) {
      this.prevFace = { color: new Color(this.color), i: faceIndex, face }
    } else if (this.prevFace.i !== faceIndex) {
      const nextPrevColor = this.getFaceColor(face)
      this.setFaceColor(this.prevFace.face, this.prevFace.color)
      this.prevFace = { i: faceIndex, face, color: nextPrevColor }
    }
    this.setFaceColor(face, currentColor)
  }
  colorFaceOnClick(faceIndex: number, face: Face, tagObj: TAG) {
    if (!tagObj) return
    const { tag, color } = tagObj
    const isPrev = this.prevFace.i === faceIndex
    const colorRGB = new Color(color)
    let item: keyof SelectedFaces
    for (item in this.selectedFaces) {
      // TODO: fix performance issue
      this.selectedFaces[item] = this.selectedFaces[item].filter((faceObj) => faceObj.i !== faceIndex)
    }

    if (tag === TAG_CONFIG_FIELD.ERASE) {
      this.setFaceColor(face, new Color(this.color))
      isPrev && (this.prevFace.color = new Color(this.color))
    } else {
      this.selectedFaces[tag].push({ i: faceIndex, face, color: colorRGB })
      this.setFaceColor(face, colorRGB)
      isPrev && (this.prevFace.color = colorRGB)
    }
  }
  resetFaces() {
    let item: keyof SelectedFaces
    for (item in this.selectedFaces) {
      this.selectedFaces[item].forEach(({ face }) => this.setFaceColor(face, new Color(this.color)))
      this.selectedFaces[item] = []
    }
    this.prevFace.color = new Color(this.color)
    jobStore.resetIFaces()
  }
  colorAllSelected() {
    let faceType: keyof SelectedFaces
    for (faceType in this.selectedFaces) {
      this.selectedFaces[faceType].forEach(({ i, face, color }) => {
        this.setFaceColor(face, color)
      })
    }
  }
  setConfToStore() {
    let faceType: keyof SelectedFaces
    for (faceType in this.selectedFaces) {
      const arr: number[] = []
      this.selectedFaces[faceType].forEach(({ i }) => arr.push(i))
      jobStore.setFaces(faceType, arr)
    }
  }

  //position size
  setSize() {
    const bbox = new Box3().setFromObject(this.mesh)
    const size = bbox.getSize(new Vector3())
    this.size = [size.x, size.y, size.z]
  }
  centerGroup() {
    this.mesh.position.x = 0
    this.mesh.position.y = 0
  }
  lift(suppHeight = 0) {
    const bbox = new Box3().setFromObject(this.group)
    const modelBase = bbox.min.z
    const center = bbox.getCenter(new Vector3())
    if (!Number.isFinite(modelBase)) return

    const extraZ = suppHeight ? suppHeight : this.hsub
    this.group.position.x = this.group.position.x - center.x
    this.group.position.y = this.group.position.x - center.y
    this.group.position.z = this.group.position.z - modelBase + extraZ
  }
  down() {
    this.group.position.z = 0
  }

  //rotation scaling
  unit2mmConstant(unit: string) {
    let conversConst = 1
    if (unit === 'm') conversConst = 1000
    if (unit === 'cm') conversConst = 10
    if (unit === 'inch') conversConst = 25.4
    return conversConst
  }
  setScaling([value]: TJobConfig['scaling']) {
    // const scaleFactor = value * this.unit2mmConstant('mm')
    this.group.scale.set(value, value, value)
    this.lift()
  }
  setDegRotation(rot: TJobConfig['RotationalAngle']) {
    const [x, y, z] = [deg2rad(rot[0]), deg2rad(rot[1]), deg2rad(rot[2])]
    this.group.rotation.set(x, y, z)
    this.updateRot()
  }
  updateRot() {
    if (!this.isRotationMode) return
    const { x, y, z } = this.group.rotation
    if (x !== this.oldRotation.x || y !== this.oldRotation.y || z !== this.oldRotation.z) {
      this.oldRotation = { x, y, z }
      this.lift()
    }
  }
}
